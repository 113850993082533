import {GET_USER_GALLERY, GET_USER_GALLERY_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import { is_login } from './is_login';


export const getUserGalleryActions = async (token) => {
    
    try {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.TF_GALLERY}`, config)
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }



}

