import {GET_VIRTUAL_SHOWROOMS, VIRTUAL_SHOWROOMS_ERROR} from '../types'

const initialState = {
    virtual_showrooms:[],
    loading_virtual_showrooms:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_VIRTUAL_SHOWROOMS:
        return {
            ...state,
            virtual_showrooms:action.payload,
            loading_virtual_showrooms:false
        }
        case VIRTUAL_SHOWROOMS_ERROR:
            return{
                loading_virtual_showrooms: false, 
                error: action.payload 
            }
        default: return state
    }

}