// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAm2XxlpsfA54ksROD0_HHtS8U4Sqe8kto",
    authDomain: "tradefindry.firebaseapp.com",
    projectId: "tradefindry",
    storageBucket: "tradefindry.appspot.com",
    messagingSenderId: "41787475162",
    appId: "1:41787475162:web:34f47cbad42ddc0a0ad1e3",
    measurementId: "G-GJEH85KZL8"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const get_token = () => {
    return getToken(messaging, { vapidKey: 'BOGwhk05D5TjiJlUmQpq78yc6AwgaPeZUaQDkyGyqoBMZtyjiOkYCCnSsA4VL4K2DBt07KfxhTDSsxichAJoKnU' }).then((currentToken) => {
        if (currentToken) {
            // console.log('current token for client: ', currentToken);
            // setTokenFound(true);
            return currentToken;
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            // console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            return "";
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return "";
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});




