import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getActiveTagActions } from '../actions/activeTagActions';
import { removeBreadcurmbAction } from '../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../actions/subMenu1Actions';
import { getSubMenu2 } from '../actions/subMenu2Actions';
import Breadcrumb from './Breadcrumb';
import SearchField from './SearchField';

const BannerWithSearch = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    return (
        <>
            <section class="dashboard_warp mb-8">
                <div class="dashboard_bg login_signup inner_page">
                    <div class="myrow">
                        <div class="left_btn">
                            
                            {location.pathname != "/login_dashboard" ?
                                <Link to="/login_dashboard" class="backhome mr-2" onClick={() => {
                                    dispatch(getActiveTagActions(""));
                                    dispatch(getSubMenu1([]));
                                    dispatch(getSubMenu2([]));
                                    dispatch(removeBreadcurmbAction(0));
                                    // navigate('/login_dashboard');
                                }}>
                                    <span>home</span> Back To Home</Link>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div class="dashboard_text text-center">
                    <div class="myrow">
                        <h1 class="avery">The Designer’s Source for the Source</h1>
                        <div class="search_bar mt-1">
                            {/* <form action="/action_page.php">
                                <input type="search" name="search" placeholder="Quick Find" class="input_field" autocomplete="off" />
                                <input type="submit" class="submit_button" />
                            </form> */}
                            <form onSubmit={(e) => { e.preventDefault(); }}>
                                <SearchField />
                                <input class="submit_button" />
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Breadcrumb />
        </>
    );
};

export default BannerWithSearch;