import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { RegistrationAction } from '../../actions/RegistrationAction';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux'
import { isLogin } from '../../actions/isLoginAction'
import { professionalAffiliationOpt, recaptcha_site_key } from '../../constants';
import ReCAPTCHA from "react-google-recaptcha";

const RegistrationForm = () => {
    let navigate = useNavigate();

    const [tradeProfessional, setTradeProfessional] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [C_password, setC_Password] = useState("");

    const [businessCategory, setBusinessCategory] = useState("");
    const [businessCategoryId, setBusinessCategoryId] = useState(0);

    const [companyName, setCompanyName] = useState("");
    const [companyZip, setCompanyZip] = useState("");

    const [professionalAffiliation, setProfessionalAffiliation] = useState("");
    const [professionalAffiliationId, setProfessionalAffiliationId] = useState(0);

    const [businessCard, setBusinessCard] = useState("");


    const [errors, setErrors] = useState({
        firstNameError: "",
        lastNameError: "",
        phoneNumberError: "",
        emailError: "",
        passwordError: "",
        cPasswordError: "",

    });


    const getBusinessCategories = useSelector(state => state.getBusinessCategories)
    const { loading_business_categories, error, business_categories } = getBusinessCategories

    const emptyAllFields = () => {

        setTradeProfessional(false);
        setTermsConditions(false);

        setFirstName("");
        setLastName("");
        setMobileNumber("");
        setEmail("");
        setPassword("");
        setC_Password("");
        setBusinessCategory("");
        setBusinessCategoryId(0);
        setCompanyName("");
        setCompanyZip("");
        setProfessionalAffiliation("");
        setProfessionalAffiliationId(0);
        setBusinessCard("");
    }


    const formSubmit = (e) => {

        e.preventDefault();
        formValidate().then((result) => {
            if (result) {

                const formData = new FormData();



                

                formData.append('is_pro', tradeProfessional ? 1 : 0);
                formData.append('first_name', firstName);
                formData.append('last_name', lastName);
                formData.append('mobile_no', mobileNumber);
                formData.append('email', email);
                formData.append('password', password);

                if (tradeProfessional) {
                    formData.append('business_name', companyName);
                    formData.append('business_category', businessCategoryId);
                    if (businessCard != "") {
                        formData.append('card_image', businessCard);
                    }
                    formData.append('business_zip', companyZip);
                    formData.append('affiliation_number', null);
                    formData.append('professional_affiliation', professionalAffiliation);
                    formData.append('professional_affiliation_other', null);

                }



                // Details of the uploaded file
                RegistrationAction(formData)
                    .then(res => {
                        const data = res.data;

                        alert(data.response.message)
                        if (data.status == 1) {
                            emptyAllFields();
                            $(".fade").click()

                            
                        }

                        
                    })
                    .catch(error => {
                        console.log(error);
                    })


            }
        })

    }

    const formValidate = async () => {

        return new Promise((resolve, reject) => {
            let status = true;
            if (!/^[A-Za-z ]+$/.test(firstName)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    firstNameError: "Invalid first name"
                }))
            }
            if (!/^[A-Za-z ]+$/.test(lastName)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    lastNameError: "Invalid last name"
                }))
            }
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    emailError: "Invalid email"
                }))
            }
            if (!/^[0-9 ()-]*$/.test(mobileNumber)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    phoneNumberError: "Invalid mobile number"
                }))
            }
            if (password != "" && !/^[a-zA-Z0-9]+$/.test(password)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    passwordError: "password contain alphanumeric only"
                }))
            }
            if (password != C_password) {
                status = false;
                setErrors(error => ({
                    ...error,
                    cPasswordError: "password and confirm password does not match"
                }))
            }
            resolve(status);

        });
    }
    const [reCaptcha, setReCaptcha] = useState(false);

    const onChangeReCaptcha = (value) => {
        if (value == null) {
            setReCaptcha(false);
        }
        else {
            setReCaptcha(true);
        }
    }
    return (
        <div class="register_form">
            <div class="modal fade" id="registerFormModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog mt-4">
                    <div class="modal-content">
                        <div class="modal-header my-3">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container h-100">
                                <div class="d-flex justify-content-center h-100">
                                    <div class="user_card">
                                        <div class="d-flex justify-content-center">
                                            <div class="brand_logo_container">
                                                <img src="images/logo.jpg" class="brand_logo" alt="Logo" />
                                            </div>
                                            <h3>Sign Up for TRADE FINDRY Access</h3>
                                        </div>
                                        <div class="d-flex justify-content-center form_container">
                                            <form onSubmit={(e) => {
                                                formSubmit(e);
                                            }}>
                                                <div class="row">
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="First Name">First Name<span class="red_require">*</span></label>
                                                            <input type="text" name="first_name" class="form-control input_user" value={firstName}
                                                                onChange={(e) => {
                                                                    setFirstName(e.target.value)
                                                                    setErrors(error => ({
                                                                        ...error,
                                                                        firstNameError: ""
                                                                    }))
                                                                }}
                                                                required
                                                            />
                                                            <span style={{ color: "red", fontSize: 12 }}>{errors.firstNameError}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="First Name">Last Name<span class="red_require">*</span></label>
                                                            <input type="text" name="last_name" class="form-control input_user" value={lastName}
                                                                onChange={(e) => {
                                                                    setLastName(e.target.value)
                                                                    setErrors(error => ({
                                                                        ...error,
                                                                        lastNameError: ""
                                                                    }))
                                                                }}
                                                                required
                                                            />
                                                            <span style={{ color: "red", fontSize: 12 }}>{errors.lastNameError}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Mobile Number">Mobile Number<span class="red_require">*</span></label>
                                                            <input type="tel" name="mobile_number" class="form-control input_pass" value={mobileNumber}
                                                                onChange={(e) => {
                                                                    setMobileNumber(e.target.value)
                                                                    setErrors(error => ({
                                                                        ...error,
                                                                        phoneNumberError: ""
                                                                    }))

                                                                }}
                                                                required
                                                            />
                                                            <span style={{ color: "red", fontSize: 12 }}>{errors.phoneNumberError}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Email">Email<span class="red_require">*</span></label>
                                                            <input type="text" name="Email" class="form-control input_user" value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value)
                                                                    setErrors(error => ({
                                                                        ...error,
                                                                        emailError: ""
                                                                    }))
                                                                }}
                                                                required
                                                            />
                                                            <span style={{ color: "red", fontSize: 12 }}>{errors.emailError}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Password">Password<span class="red_require">*</span></label>
                                                            <input type="password" name="Password" class="form-control input_pass" value={password}
                                                                onChange={(e) => {
                                                                    setPassword(e.target.value)
                                                                    setErrors(error => ({
                                                                        ...error,
                                                                        passwordError: ""
                                                                    }))
                                                                }}
                                                                required
                                                            />
                                                            <span style={{ color: "red", fontSize: 12 }}>{errors.passwordError}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Password">Confirm Password<span class="red_require">*</span></label>
                                                            <input type="password" name="Password" class="form-control input_pass" value={C_password}
                                                                onChange={(e) => {
                                                                    setC_Password(e.target.value)
                                                                    setErrors(error => ({
                                                                        ...error,
                                                                        cPasswordError: ""
                                                                    }))
                                                                }}
                                                                required
                                                            />
                                                            <span style={{ color: "red", fontSize: 12 }}>{errors.cPasswordError}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 mb-4">
                                                        <div class="form-group checkbox_style" >
                                                            <input type="checkbox" checked={tradeProfessional} name="trade_professional" id="trade_professional" value="trade_professional"
                                                                onClick={() => { setTradeProfessional(!tradeProfessional) }}
                                                            />
                                                            <label for="trade_professional">I am a Trade Professional</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class={`row ${tradeProfessional == true ? "" : "hiding_form"}`}>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Business Category">Business Category<span class="red_require">*</span></label>
                                                            <select disabled={!tradeProfessional} class="form-control input_user" aria-label="Default select example" required>
                                                                <option selected>{businessCategory}</option>
                                                                {
                                                                    !loading_business_categories && business_categories.status == 1 ?
                                                                        business_categories.response.map((item, index) => (
                                                                            <option key={item.id} value={item.id}
                                                                                onClick={() => {
                                                                                    setBusinessCategory(item.name);
                                                                                    setBusinessCategoryId(item.id);
                                                                                }}
                                                                            >{item.name}</option>
                                                                        ))
                                                                        : null
                                                                }
                                                            </select>
                                                            
                                                        </div>

                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="First Name">Company Name<span class="red_require">*</span></label>
                                                            <input type="text" name="company_name" class="form-control input_user" value={companyName}
                                                                disabled={!tradeProfessional}
                                                                onChange={(e) => { setCompanyName(e.target.value) }}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Company Zip">Company Zip Code<span class="red_require">*</span></label>
                                                            <input type="number" name="company_zip" class="form-control input_pass" value={companyZip}
                                                                disabled={!tradeProfessional}
                                                                onChange={(e) => { setCompanyZip(e.target.value) }}
                                                                required

                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Professional Affiliation">Professional Affiliation<span class="red_require">*</span></label>
                                                            <select disabled={!tradeProfessional} class="form-control input_user" aria-label="Default select example" required >
                                                                <option selected>{professionalAffiliation}</option>
                                                                {

                                                                    professionalAffiliationOpt.map((item, index) => (
                                                                        <option key={item.id} value={item.id}
                                                                            onClick={() => {
                                                                                setProfessionalAffiliation(item.name);
                                                                                setProfessionalAffiliationId(item.id);
                                                                            }}
                                                                        >{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Upload Business Card">Upload Business Card</label>
                                                            <input type="file" name="business_card" class="input_pass reg_inputFile"
                                                                disabled={!tradeProfessional}
                                                                onChange={(e) => { setBusinessCard(e.target.files[0]) }}

                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row flex_center yi_form_bottom">
                                                    <div class="col-xs-6 col-sm-6 col-md-6">
                                                        <div className='mb-4'>
                                                            <ReCAPTCHA
                                                                sitekey={recaptcha_site_key}
                                                                onChange={onChangeReCaptcha}
                                                            />
                                                        </div>
                                                        <div class="form-group checkbox_style" >
                                                            <input type="checkbox" checked={termsConditions} name="termsandcondition" id="termsandcondition" value="termsandcondition"
                                                                onClick={() => { setTermsConditions(!termsConditions) }}
                                                            />
                                                            <label for="termsandcondition">I accept Terms & Condition</label>
                                                        </div>

                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6">
                                                        <div class="form-group flex_center">
                                                            <a href="#" class="links">Privacy Policy</a>
                                                            <button type="submit" disabled={termsConditions && reCaptcha?false:true} name="button" class="btn login_btn"
                                                            
                                                            >Sign Up</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationForm;