import $ from 'jquery'; 
$(document).ready(function(){
  $(function() { // Dropdown toggle
    // $('.dropdown-toggle').click(function() { $(this).next('.dropdown').slideToggle();
    // });
    
    // $(document).click(function(e) 
    // { 
    // var target = e.target; 
    // if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
    // //{ $('.dropdown').hide(); }
    //   { $('.dropdown').slideUp(); }
    // });
    $(document).on('click', '.mobile_narbar', function(event) {
    // $(".mobile_narbar").click(function(){
      $(".sidebar").toggleClass("active-s");
      });

    });

    $(document).on('click', '.dropdown-toggle', function(event) {
      // $(".mobile_narbar").click(function(){
        // $(".sidebar").toggleClass("active-s");

        // $('.dropdown-toggle').click(function() {
           $(this).next('.dropdown').slideToggle(); 
        // });
  
      });

      $(document).click(function(e) 
        { 
        var target = e.target; 
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) 
        //{ $('.dropdown').hide(); }
          { $('.dropdown').slideUp(); }
        });


    // $(function(){
    //   $('.box').on('click',function(){
    //     if ($(this).text()==' Like'){
    //       $(this).removeClass('entypo-thumbs-up').addClass('entypo-thumbs-down').text(' Unlike');
    //     }else{
    //       $(this).removeClass('entypo-thumbs-down').addClass('entypo-thumbs-up').text(' Like');
    //     }
    //   })
    // });

    //   $(document).ready(function(){
    //   $(function() { // Dropdown toggle
    //     $('.dropdown-toggle').click(function() { $(this).next('.dropdown').slideToggle(); 
    //     });
        
        
        // })
        
    // });

});

