import {GET_DESIGN_CENTER, GET_DESIGN_CENTER_ERROR} from '../types'

const initialState = {
    designCentersData:[],
    loading_designCentersData:true
}

export default function(state = initialState, action){
    switch(action.type){
        
        case GET_DESIGN_CENTER:
            return {
            ...state,
            designCentersData:action.payload,
            loading_designCentersData:false

        }
        case GET_DESIGN_CENTER_ERROR:
            return{
                loading_designCentersData: false, 
                error: action.payload 
            }
        default: return state
    }

}