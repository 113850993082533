// import './App.css';
import React, { useEffect, useState } from 'react'

import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header1 from './components/Header/Header1';
import Header2 from './components/Header/Header2';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer'
import { isLogin as login } from '../src/actions/isLoginAction'
import { useDispatch, useSelector } from 'react-redux'
import { onMessageListener } from './firebase';
import { Toast,ToastContainer } from 'react-bootstrap';
// import { get_token, onMessageListener } from './firebase';




function App() {

  const dispatch = useDispatch()


  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const [notifications, setNotifications] = useState([]);
  // const [isTokenFound, setTokenFound] = useState(false);

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    // let newNotifications = notifications;
    // newNotifications.push({ title: payload.notification.title, body: payload.notification.body });
    // setNotifications(newNotifications);
    setNotifications(notifications => (
      [
        ...notifications,
        { title: payload.notification.title, body: payload.notification.body }
      ]
    ))
  }).catch(err => console.log('failed: ', err));

  useEffect(() => {
    dispatch(login(localStorage.getItem('token')))

  }, [dispatch])


  const is_login = useSelector(state => state.isLogin)
  const { loading, error, isLogin } = is_login


  const getCurrentPage = useSelector(state => state.getCurrentPage)


  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="bg-dark position-relative"
        style={{ 
          zIndex:999 
        }}
      >
        <ToastContainer position="top-end" className="p-3">
          
          {
            notifications.map((notifiaciotn,index)=>(

           
          <Toast onClose={()=>{
            setNotifications(notifications.filter((item,itemIndex)=>itemIndex != index))
          }}>
            <Toast.Header>
              <img src="/favicon.png" className="rounded me-2" alt="" width={20} height={20} />
              <strong className="me-auto">{notifiaciotn.title}</strong>
              {/* <small className="text-muted">2 seconds ago</small> */}
            </Toast.Header>
            <Toast.Body>{notifiaciotn.body}</Toast.Body>
          </Toast>
           ))
          }
        </ToastContainer>
      </div>
      <Router>
        {
          // loading == false && isLogin.status == 1 ?
          !loading && isLogin.status == 1 ?
            getCurrentPage.currentPageName == "editor_board" ?
              null : <Header2 />
            :
            <Header1 />
        }

        <Main />
        <Footer />
      </Router>
    </>
  );
}

export default App;
