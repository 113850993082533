import {GET_ACTIVE_TAG, ACTIVE_TAG_ERROR} from '../types'

export const getActiveTagActions = (category_id) => async dispatch => {
    
    try{
        dispatch( {
            type: GET_ACTIVE_TAG,
            payload: category_id
        })
    }
    catch(error){
        dispatch( {
            type: ACTIVE_TAG_ERROR,
            payload: error,
        })
    }

}

