import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveTagActions } from '../actions/activeTagActions';
import { removeBreadcurmbAction, setBreadcurmbAction, updateBreadcurmbAction } from '../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../actions/subMenu1Actions';
import { getSubMenu2 } from '../actions/subMenu2Actions';

const Breadcrumb = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    useEffect(() => {
            dispatch(setBreadcurmbAction("Home",()=>{
                dispatch(getActiveTagActions(""));
                dispatch(getSubMenu1([]));
                dispatch(getSubMenu2([]));
                dispatch(removeBreadcurmbAction(0));
                navigate('/login_dashboard');
            },0));


    }, [dispatch])

    const getBreadcurmb = useSelector(state => state.getBreadcurmb)
    const { loading_breadcrumbsList, breadcrumbsList } = getBreadcurmb;
    
    return (
        breadcrumbsList.length > 1?
        <section class="breadcrumb_section mt-8">
            <div class="myrow inner_container">
                <div class="col_12 breadcrumb_col">
                    {
                        breadcrumbsList
                        .filter((item)=>{return item!=null;})
                        .map((breadcrumb,index,newArray) => (
                            
                            <>
                                <a href="#" class="breadcrumb_inner" onClick={
                                    ()=>{
                                        if(typeof breadcrumb.actionFunction !== 'function'){
                                            console.log("in if");
                                                dispatch(getActiveTagActions(""));
                                                dispatch(getSubMenu1([]));
                                                dispatch(getSubMenu2([]));
                                                dispatch(removeBreadcurmbAction(0));
                                                navigate('/login_dashboard');
                                        }else{
                                            breadcrumb.actionFunction();
                                        }

                                    }
                                    }>
                                    {breadcrumb.title}
                                </a>
                                {newArray.length-1 != index?
                                    <span class="spt"> > </span>
                                    :null
                                }
                            </>
                        ))
                    }
                </div>
            </div>
        </section>
        :null
    );
};

export default Breadcrumb;