
import React, { useEffect, useState } from 'react'
import '../../assets/css/north.css';
import { isLogin as login } from '../../actions/isLoginAction'
import { vendorsListByMapAction } from '../../actions/vendorsListByMapAction'
import { getVendorDetailsAction } from '../../actions/getVendorDetailsAction'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import Map_pin from "../Map_pin";
import { MarkFavoriteVendorAction } from '../../actions/MarkFavoriteVendorAction';
import BannerWithSearch from '../BannerWithSearch';
import VednorsList from '../Vendor/VednorsList';

const VendorsListByMap = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(login(localStorage.getItem('token')))
        getVendors();
    }, [dispatch])

    const getVendors = () => {
        dispatch(vendorsListByMapAction(localStorage.getItem('token'), JSON.parse(localStorage.getItem('designCenter'))))

    }
    
    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
    }, [])

    const vendorsListByMap = useSelector(state => state.vendorsListByMap)
    const { loading_vendorsListByMapData, vendorsListByMapData, designCenter } = vendorsListByMap

    

    const vendorDetailsClick = (vendorDetails) => {
        localStorage.setItem('vendorDetails', JSON.stringify(vendorDetails));
        dispatch(getVendorDetailsAction(vendorDetails))
    }

    const [search, setSearch] = useState('');

    const markFavorite = (vendor_id, favorite) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('vendor_id', vendor_id);
        formData.append('favorite', favorite);
        MarkFavoriteVendorAction(token, formData)
            .then((result) => {
                getVendors();

            }).catch((err) => {

            });
    }

    return (

        <div class="rightside">
            <BannerWithSearch />

            {
                !loading_vendorsListByMapData && vendorsListByMapData.status == 1 ?
                <VednorsList vendors_list={vendorsListByMapData.response.vendors} category={JSON.stringify(designCenter)} />
                    :
                    <section class="loaction_section mr-3">
                        <div class="row inner_row find_row">
                            <div class="col-xs-12 col-sm-12 col-md-12 mb-5 full-with-search-bar">
                                <h3>{JSON.parse(localStorage.getItem("designCenter")).name}</h3>
                                <div class="search_bar">
                                    <form action="/action_page.php">
                                        <input type="text" name="search" placeholder="Find" class="input_field" />
                                        <input type="submit" class="submit_button" />
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="row inner_row location_row">
                            <div class="col-xs-6 col-sm-6 col-md-6 mb-4 mr-4">
                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                <div class="map_div">
                                    <iframe className='border-0' src={JSON.parse(localStorage.getItem("designCenter")).image} width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
            }
        </div>

    );
};

export default VendorsListByMap;