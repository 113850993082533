import {GET_VENDORS_LIST_CATEGORY, GET_VENDORS_LIST_CATEGORY_ERROR} from '../types'

const initialState = {
    vendorsListByCategoryData:[],
    category:'',
    loading_vendorsListByCategoryData:true
}

export default function(state = initialState, action){
    switch(action.type){
        
        case GET_VENDORS_LIST_CATEGORY:
            return {
            ...state,
            vendorsListByCategoryData:action.payload.res,
            category:action.payload.category,
            loading_vendorsListByCategoryData:false

        }
        case GET_VENDORS_LIST_CATEGORY_ERROR:
            return{
                loading_vendorsListByCategoryData: false, 
                error: action.payload 
            }
        default: return state
    }

}