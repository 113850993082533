import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveTagActions } from '../../actions/activeTagActions';
import { getPage as get_page } from '../../actions/getPageAction'
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../../actions/subMenu1Actions';
import { getSubMenu2 } from '../../actions/subMenu2Actions';
import { closeSidebar } from '../Functions';
import MenuItems from './MenuItems';

const SidebarBeforeLogin = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const menuItems = [
        {
            img: "/images/what’s_new.svg",
            name: "What’s New",
            slug: "whats-new_desktop",
            to: "/whats_new",
            loginRequired: false,
            breadcrumbTitle: "What’s New",
            tag: "WhatsNew",
        },
        {
            img: "/images/Associations.svg",
            name: "Trade Associations",
            slug: "professional-associations_web",
            to: "/associations",
            loginRequired: false,
            breadcrumbTitle: "Associations",
            tag: "associations",

        },
        {
            img: "/images/MarketsExpos.svg",
            name: "Markets/Expos",
            slug: "markets-expos_web",
            to: "/martek_expos",
            loginRequired: false,
            breadcrumbTitle: "Markets/Expos",
            tag: "marketsExpos",
        },
    ]

    return (
        <div class="sidebar" id="sidebar">
            <nav>
                <div class="mobile_narbar">
                    <a href="#">|||</a>
                </div>
            </nav>
            <div class="mobile_menu show">
                <div class="logo-details">
                    <span class="logo_name"><Link to="/dashboard" onClick={closeSidebar}>TRADE FINDRY</Link></span>
                </div>
                <div class="menu_srcoll">
                    <div class="withoutlogin_btn_div">
                        <div class="mt-2 mb-4">
                            <a href="https://portal.tradefindry.com/" target={"_blank"} class="same_btn vendor_sign_up">Vendor Sign Up</a>
                        </div>
                        <div class="contact_us mt-2 mb-4">
                            <Link to="/Contactus" class="same_btn"
                                onClick={() => {
                                    dispatch(setBreadcurmbAction("Contact Us", () => {
                                        // updatCategoryDispatch("contactus");
                                        // navigate("/Contactus");
                                        dispatch(getActiveTagActions("Contactus"))
                                        dispatch(getSubMenu1([]));
                                        dispatch(getSubMenu2([]));
                                        dispatch(removeBreadcurmbAction(1));
                                    }, 1));
                                    closeSidebar();

                                }}
                            >Contact Us</Link>
                            {/* <a href="#" class="same_btn contact_us">Contact Us</a> */}
                        </div>
                    </div>
                    <div class="cat_seach">
                        <h4>Quick Links</h4>
                    </div>
                    <MenuItems itemList={menuItems} />
                    {/* <ul class="nav-links">
                        {
                            menuItems.map((item, index) => (
                                <>
                                    {
                                        <li>
                                            <Link to={item.to}

                                                onClick={() => {
                                                    if (item.slug != null) {
                                                        dispatch(get_page(localStorage.getItem('token'), item.slug))
                                                        localStorage.setItem('slug', item.slug);
                                                    }
                                                }}
                                            >
                                                <img src={item.img} alt={item.name} />
                                                <span class="links_name">{item.name}</span>
                                            </Link>
                                        </li>
                                    }

                                </>
                            ))
                        }

                    </ul> */}
                </div>
            </div>
        </div>
    );
};

export default SidebarBeforeLogin;


// import React from 'react';


// function menuItems(itemLIst) {
//     const dispatch = useDispatch()

//     return (
//         <ul class="nav-links">
//             {
//                 itemLIst.map((item, index) => (
//                     <>
//                         {
//                             <li>
//                                 <Link to={item.to}

//                                     onClick={() => {
//                                         if (item.slug != null) {
//                                             dispatch(get_page(localStorage.getItem('token'), item.slug))
//                                             localStorage.setItem('slug', item.slug);
//                                         }
//                                     }}
//                                 >
//                                     <img src={item.img} alt={item.name} />
//                                     <span class="links_name">{item.name}</span>
//                                 </Link>
//                             </li>
//                         }

//                     </>
//                 ))
//             }

//         </ul>
//     )
// }