import { GET_PROJECTS, GET_PROJECTS_ERROR } from '../types'

const initialState = {
    getProjectsData: [],
    loading_getProjects: true
}

export default function (state = initialState, action) {

    switch (action.type) {

        case GET_PROJECTS:
            return {
                ...state,
                getProjectsData: action.payload,
                loading_getProjects: false

            }
        case GET_PROJECTS_ERROR:
            return {
                loading_getProjects: false,
                error: action.payload
            }
        default: return state
    }

}