import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPage as get_page } from '../../actions/getPageAction'
// import '../../assets/css/dashboardstyle.css';
import '../../assets/css/dashboard.css';
import { Link, useNavigate } from 'react-router-dom';
import SearchField from '../SearchField'
import { isLogin as login } from '../../actions/isLoginAction'
import LoginForm from '../Header/LoginForm';
import RegistrationForm from '../Header/RegistrationForm';
import ForgotPasswordForm from '../Header/ForgotPasswordForm';


const Dashboard = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const is_login = useSelector(state => state.isLogin);
    const { loading, error, isLogin } = is_login;

    useEffect(() => {
        dispatch(login(localStorage.getItem('token')));
    }, [dispatch])
    
    useEffect(() => {
        if (localStorage.getItem('token') != null && localStorage.getItem('token') != "") {
            if (isLogin.status == 1) {
                navigate('/login_dashboard');
            }
        }
        window.scrollTo(0, 0);
    },[])

    
    

    return (
        <>
            <ForgotPasswordForm />
            <LoginForm />
            <RegistrationForm />

            <div class="rightside">
                <section class="dashboard_warp">
                    <div class="dashboard_bg login_signup">
                        <div class="myrow">
                            {/* <div class="left_btn">
                                <Link to="/dashboard" class="backhome mr-2"><span>home</span> Back To Home</Link>
                                // <a href="#" class="backhome mr-2"><span>home</span> Back To Home</a>
                            </div> */}
                        </div>
                    </div>
                    <div class="dashboard_text text-center">
                        <div class="myrow">
                            <h1 class="avery">The Designer’s Source for the Source</h1>
                            <div class="search_bar mt-1">
                                
                                <form onSubmit={(e) => { e.preventDefault(); }}>
                                    <SearchField />
                                    <input class="submit_button" />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="ya_two_section with_out_login_two_list list_logo_two mb-8 mt-6 ">
                    <div class="myrow row_flex">
                        <div class="col_6">
                            <a href="#" class="yb_rot" data-bs-toggle="modal" data-bs-target="#registerFormModal">
                                <h3 class="avery">Register</h3>
                                <p>Sign up for trade-only access </p>
                            </a>
                        </div>
                        <div class="col_6">
                            <a href="#" class="yb_rot" data-bs-toggle="modal" data-bs-target="#loginFormModal">
                                <h3 class="avery">Log-In</h3>
                                <p>Welcome Back!</p>
                            </a>
                        </div>
                    </div>
                </section>
                <section class="single_text_section mt-4 mb-6">
                    <div class="myrow row_flex single_text text-center">
                        <div class="col_12 welcome mb-4">
                            <h2 class="avery">Welcome!</h2>
                            <p><strong>Interior Designers and all Home/Build Professionals</strong></p>
                        </div>
                        <div class="col_12 register_today">
                            <h3 class="avery">Register Today</h3>
                            <p>Trade Findry is the online tool to make finding products for your next project free, faster, easier… and local.</p>
                            <p> Originating in Arizona, Trade Findry brings all the best local products and vendors to you in one place.</p>
                            <p>Start building the essential relationships with knowledgeable local vendors. And leave behind the frustrating and time-wasting task of sourcing from unknown online sellers.</p>
                        </div>
                    </div>
                </section>

            </div>
            
        </>
    );
};

export default Dashboard;