import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getActiveTagActions } from '../../actions/activeTagActions';
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../../actions/subMenu1Actions';
import { getSubMenu2 } from '../../actions/subMenu2Actions';

const Footer = () => {
    const dispatch = useDispatch()

    return (
        //     <div class="rightside mr-150">
        //     <h1>Footer</h1>
        // </div>

        <footer class="footer pt-3 pb-3 bg-white">
            <div class="myrow row_flex text-center">
                <div class="col_4 footer_menu_div">
                    <ul class="footer_menu">
                        <li>
                            {/* <a href="#">Contact Us</a> */}
                            <Link to="/Contactus" class=""
                                onClick={() => {
                                    dispatch(setBreadcurmbAction("Contact Us", () => {
                                        // updatCategoryDispatch("contactus");
                                        // navigate("/Contactus");
                                        dispatch(getActiveTagActions("Contactus"))
                                        dispatch(getSubMenu1([]));
                                        dispatch(getSubMenu2([]));
                                        dispatch(removeBreadcurmbAction(1));
                                    }, 1));
                                }}
                            >Contact Us</Link>
                        </li>
                        <li><a href="#">About Us</a></li>
                        <li><a href="https://portal.tradefindry.com/" target={"_blank"}>Vendor Sign-Up</a></li>
                    </ul>
                </div>
                <div class="col_4 footer_logo">
                    <a href="#"><img src="images/footer_logo.jpg" alt="footer_logo" /></a>
                </div>
                <div class="col_4 social_div">
                    <ul class="media_link">
                        <li class="facebook"><a href="https://www.facebook.com/tradefindry/" target="_blank">facebook</a></li>
                        <li class="insta"><a href="https://www.instagram.com/trade_findry/" target="_blank">insta</a></li>
                        <li class="link"><a href="#" target="_blank">link</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;