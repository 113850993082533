import {GET_USER_PROFILE, GET_USER_PROFILE_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";


// export const getProfileAction = (token) => async dispatch => {
    
//     try{
//         const config = {
//             headers: { Authorization: `Bearer ${token}` }
//         };

//         const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.GET_PROFILE}`, config)
//         dispatch( {
//             type: GET_USER_PROFILE,
//             payload: res.data
//         })
//     }
//     catch(error){
//         console.log(error);
//         dispatch( {
//             type: GET_USER_PROFILE_ERROR,
//             payload: error,
//         })
//     }

// }
export const getProfileAction = async (token) => {
    
    try {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.GET_PROFILE}`, config)
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }



}

