import React from 'react';

const ComingSoon = () => {
    return (
        <div class="rightside mr-150">
            <section class="coming_soon">
                <div class="coming_soon_box">
                    <h2>Coming Soon</h2>
                </div>
            </section>
        </div>
    );
};

export default ComingSoon;