import {GET_VENDORS_LIST_MAP, GET_VENDORS_LIST_MAP_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import {is_login} from './is_login';

export const vendorsListByMapAction = (token,designCenter) => async dispatch => {
    try{
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.VENDORS_BY_DESIGN_CENTER:ROUTE.VENDORS_BY_DESIGN_CENTER_BEFORE_LOGIN}/${designCenter.id}`;
        const res = await axios.get(API,config);

        dispatch( {
            type: GET_VENDORS_LIST_MAP,
            payload: {"res":res.data,"designCenter":designCenter}
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: GET_VENDORS_LIST_MAP_ERROR,
            payload: error,
        })
    }

}

