import {BREADCRUMB, BREADCRUMB_ERROR, REMOVE_BREADCRUMB, REMOVE_BREADCRUMB_ERROR, UPDATE_BREADCRUMB, UPDATE_BREADCRUMB_ERROR} from '../types'

export const setBreadcurmbAction = (breadcrumb,actionFunction,index=0) => async dispatch => {
    
    try{

        dispatch( {
            type: BREADCRUMB,
            payload:  {"title":breadcrumb,"actionFunction":actionFunction,"index":index}
        })
    }
    catch(error){
        dispatch( {
            type: BREADCRUMB_ERROR,
            payload: error,
        })
    }

}

export const removeBreadcurmbAction = (index) => async dispatch => {
    
    try{
        dispatch( {
            type: REMOVE_BREADCRUMB,
            payload: index
        })
    }
    catch(error){
        dispatch( {
            type: REMOVE_BREADCRUMB_ERROR,
            payload: error,
        })
    }

}

export const updateBreadcurmbAction = (breadcrumbs) => async dispatch => {
    
    try{
        dispatch( {
            type: UPDATE_BREADCRUMB,
            payload: breadcrumbs
        })
    }
    catch(error){
        dispatch( {
            type: UPDATE_BREADCRUMB_ERROR,
            payload: error,
        })
    }

}

