import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getProfileAction } from '../../actions/getUserProfileAction';
import { isLogin } from '../../actions/isLoginAction';
import { UpdateUserProfileAction } from '../../actions/UpdateUserProfileAction';
import $ from "jquery";

const UserProfile = () => {
    const dispatch = useDispatch()
    const [userProfile, setUserProfile] = useState({});
    const [businessCategories, setBusinessCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [pass, setPass] = useState("");
    const [cPass, setCPass] = useState("");
    const [businessCard, setBusinessCard] = useState("");
    const [errors, setErrors] = useState({
        firstNameError: "",
        lastNameError: "",
        phoneNumberError: "",
        emailError: "",
        passwordError: "",
        cPasswordError: "",

    });



    useEffect(() => {
        
        getProfileAction(localStorage.getItem('token'))
            .then(res => {
                if (res.status == 1) {
                    setUserProfile(res.response.userProfile);
                    setBusinessCategories(res.response.userProfile.business_categories)
                }
            })
            .catch(error => {
                console.log(error);
            })


    }, [])

    const updateUserProfile = () => {



        formValidate().then((result) => {
            if (result) {

                const formData = new FormData();
                formData.append('first_name', userProfile.first_name);
                formData.append('last_name', userProfile.last_name);
                formData.append('mobile_no', userProfile.mobile_no);
                formData.append('email', userProfile.email);
                if (pass != '')
                    formData.append('password', pass);
                formData.append('business_category_id', selectedCategory);
                formData.append('business_name', userProfile.business_name);
                formData.append('business_zip', userProfile.business_zip);
                if (businessCard != '')
                    formData.append('card_image', businessCard);

                UpdateUserProfileAction(localStorage.getItem('token'), formData)
                    .then(res => {
                        const data = res.data;
                        
                        alert(data.response.message)
                        dispatch(isLogin(localStorage.getItem('token')))
                        if (data.status == 1) {
                            $(".fade").click()
                        }


                    })
                    .catch(error => {
                        console.log(error);
                    })

            }
        })
        // }
    }

    const formValidate = async () => {
        
        return new Promise((resolve, reject) => {
            let status = true;
            if (!/^[A-Za-z ]+$/.test(userProfile.first_name)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    firstNameError: "Invalid first name"
                }))
            }
            if (!/^[A-Za-z ]+$/.test(userProfile.last_name)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    lastNameError: "Invalid last name"
                }))
            }
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userProfile.email)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    emailError: "Invalid email"
                }))
            }
            if (!/^[0-9 ()-]*$/.test(userProfile.mobile_no)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    phoneNumberError: "Invalid mobile number"
                }))
            }
            if (pass != "" && !/^[a-zA-Z0-9]+$/.test(pass)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    passwordError: "password contain alphanumeric only"
                }))
            }
            if (pass != cPass) {
                status = false;
                setErrors(error => ({
                    ...error,
                    cPasswordError: "password and confirm password does not match"
                }))
            }
            resolve(status);

        });
    }

    return (
        userProfile !== {} ?
            <>
                <div class="profile_form">
                    <div class="modal fade" id="userProfileModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="container h-100">
                                        <div class="d-flex justify-content-center h-100">
                                            <div class="user_card">
                                                <div class="d-flex justify-content-center">
                                                    <div class="brand_logo_container">
                                                        <img src="images/logo.jpg" class="brand_logo" alt="Logo" />
                                                    </div>
                                                    <h3 style={{ paddingBottom: 0 }}>My Profile</h3>
                                                    <p style={{ paddingBottom: 20 }}>Be a part of our Findry Community!</p>
                                                </div>
                                                <div class="d-flex justify-content-center form_container">
                                                    <form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        updateUserProfile();
                                                    }}>
                                                        <div class="row">
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="First Name">First Name<span class="red_require">*</span></label>
                                                                    <input type="text" name="first_name" class="form-control input_user" value={userProfile.first_name} required
                                                                        onChange={(e) => {
                                                                            var tempUserProfile = userProfile;
                                                                            tempUserProfile.first_name = e.target.value;
                                                                            setUserProfile(userProfile => ({
                                                                                ...userProfile,
                                                                                ...tempUserProfile
                                                                            }))
                                                                            setErrors(error => ({
                                                                                ...error,
                                                                                firstNameError: ""
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <span style={{ color: "red", fontSize: 12 }}>{errors.firstNameError}</span>

                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="First Name">Last Name<span class="red_require">*</span></label>
                                                                    <input type="text" name="last_name" class="form-control input_user" value={userProfile.last_name} required
                                                                        onChange={(e) => {
                                                                            var tempUserProfile = userProfile;
                                                                            tempUserProfile.last_name = e.target.value;
                                                                            setUserProfile(userProfile => ({
                                                                                ...userProfile,
                                                                                ...tempUserProfile
                                                                            }))
                                                                            setErrors(error => ({
                                                                                ...error,
                                                                                lastNameError: ""
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <span style={{ color: "red", fontSize: 12 }}>{errors.lastNameError}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Mobile Number">Mobile Number<span class="red_require">*</span></label>
                                                                    <input type="tel" name="mobile_number" class="form-control input_pass" value={userProfile.mobile_no} required
                                                                        onChange={(e) => {
                                                                            var tempUserProfile = userProfile;
                                                                            tempUserProfile.mobile_no = e.target.value;
                                                                            setUserProfile(userProfile => ({
                                                                                ...userProfile,
                                                                                ...tempUserProfile
                                                                            }))
                                                                            setErrors(error => ({
                                                                                ...error,
                                                                                phoneNumberError: ""
                                                                            }))

                                                                        }}
                                                                    />
                                                                    <span style={{ color: "red", fontSize: 12 }}>{errors.phoneNumberError}</span>

                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Email Address">Email Address<span class="red_require">*</span></label>
                                                                    <input type="text" name="Email Address" class="form-control input_user" value={userProfile.email} required disabled
                                                                        // onChange={(e) => {
                                                                        //     // var tempUserProfile = userProfile;
                                                                        //     // tempUserProfile.email = e.target.value;
                                                                        //     // setUserProfile(userProfile => ({
                                                                        //     //     ...userProfile,
                                                                        //     //     ...tempUserProfile
                                                                        //     // }))
                                                                        //     setErrors(error => ({
                                                                        //         ...error,
                                                                        //         emailError: ""
                                                                        //     }))
                                                                        // }}
                                                                    />


                                                                    <span style={{ color: "red", fontSize: 12 }}>{errors.emailError}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Password">Password</label>
                                                                    <input type="password" name="Password" class="form-control input_pass" value={pass}
                                                                        min="8"
                                                                        onChange={(e) => {
                                                                            setPass(e.target.value)
                                                                            setErrors(error => ({
                                                                                ...error,
                                                                                passwordError: ""
                                                                            }))
                                                                        }}

                                                                    />
                                                                    <span style={{ color: "red", fontSize: 12 }}>{errors.passwordError}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Confirm Password">Confirm Password</label>
                                                                    <input type="password" name="Confirm Password" class="form-control input_pass" value={cPass}
                                                                        onChange={(e) => {
                                                                            setCPass(e.target.value)
                                                                            setErrors(error => ({
                                                                                ...error,
                                                                                cPasswordError: ""
                                                                            }))
                                                                        }}
                                                                    />
                                                                    <span style={{ color: "red", fontSize: 12 }}>{errors.cPasswordError}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Email Address">Business Category<span class="red_require">*</span></label>
                                                                    <select>
                                                                        {
                                                                            businessCategories.map((item, index) => (
                                                                                <option value={item.id} selected={selectedCategory == item.id} onClick={() => {
                                                                                    setSelectedCategory(item.id)
                                                                                }}>{item.name}</option>
                                                                            ))

                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Company Name">Company Name<span class="red_require">*</span></label>
                                                                    <input type="text" name="Company Name" class="form-control input_user" value={userProfile.business_name} required
                                                                        onChange={(e) => {
                                                                            var tempUserProfile = userProfile;
                                                                            tempUserProfile.business_name = e.target.value;
                                                                            setUserProfile(userProfile => ({
                                                                                ...userProfile,
                                                                                ...tempUserProfile
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-4 col-sm-4 col-md-4 mb-4">
                                                                <div class="form-group">
                                                                    <label class="custom-control-label" for="Company Zip Code">Company Zip Code<span class="red_require">*</span></label>
                                                                    <input pattern="[0-9]{5}" type="number" required value={userProfile.business_zip}
                                                                        onChange={(e) => {
                                                                            var tempUserProfile = userProfile;
                                                                            tempUserProfile.business_zip = e.target.value;
                                                                            setUserProfile(userProfile => ({
                                                                                ...userProfile,
                                                                                ...tempUserProfile
                                                                            }))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row yi_form_bottom">
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="form-group upload_style">
                                                                    <input type="file"
                                                                        onChange={(e) => { setBusinessCard(e.target.files[0]) }}
                                                                    />
                                                                    <span class="upload_div">Tap to Upload business card</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="form-group flex_center">
                                                                    <button type="submit" name="button" class="btn login_btn">Update</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            : null
    );
};

export default UserProfile;