import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCategories } from '../../actions/categoriesActions'
import Sidebar from '../Sidebar/Sidebar';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import WhatsNew from '../WhatsNew/WhatsNew';
import DesignCentersFindByMap from '../FindByMap/DesignCentersFindByMap';
import VendorsListByMap from '../FindByMap/VendorsListByMap';
import VendorDetails from '../Vendor/VendorDetails';
import VendorListByCategory from '../Vendor/VendorListByCategory';
import VirtualShowrooms from '../Vendor/VirtualShowrooms';
import VendorListByFavorite from '../Vendor/VendorListByFavorite';
import Contactus from '../Contactus';
import EventCalendar from '../Events/EventCalendar';
import EventDetails from '../Events/EventDetails';
import Dashboard from '../Dashboard/Dashboard';
import LoginDashboard from '../Dashboard/LoginDashboard';
import ComingSoon from '../ComingSoon';
import Editor_Board from '../Editor_Board/EditorBoard';
import { getBusinessCategoryActions } from '../../actions/getBusinessCategoryActions';
import ProjectBoards from '../Dashboard/ProjectBoards';
import Categories from '../Categories/Categories';
import ProtectedRoute from './ProtectedRoute';

const Main = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getBusinessCategoryActions())
    }, [dispatch])
    

    return (
        <>
            <div class="main">
                <Sidebar />
                <Routes>
                    <Route path="/" caseSensitive={false} element={<Navigate to="/dashboard" replace />}/>
                    <Route path="/dashboard" caseSensitive={false} element={<Dashboard />} />
                    {/* <Route path="/project_boards" caseSensitive={false} element={<ProtectedRoute component={ProjectBoards}/>} /> */}
                    <Route path="/project_boards" caseSensitive={false} element={<ProjectBoards />} />
                    <Route path="/categories" caseSensitive={false} element={<Categories />} />
                    <Route path="/whats_new" caseSensitive={false} element={<WhatsNew />} />
                    <Route path="/martek_expos" caseSensitive={false} element={<WhatsNew />} />
                    <Route path="/associations" caseSensitive={false} element={<WhatsNew />} />
                    {/* <Route path="/green" caseSensitive={false} element={<WhatsNew />} /> */}
                    <Route path="/brand" caseSensitive={false} element={<WhatsNew />} />
                    <Route path="/find_by_map" caseSensitive={false} element={<DesignCentersFindByMap />} />
                    <Route path="/vendors_list" caseSensitive={false} element={<VendorsListByMap />} />
                    <Route path="/vendor_details" caseSensitive={false} element={<VendorDetails />} />
                    {/* <Route path="/vendor_details" caseSensitive={false} element={<VendorDetails />} /> */}
                    <Route path="/vendor_list_category" caseSensitive={false} element={<VendorListByCategory />} />
                    <Route path="/vendor_list_favorite" caseSensitive={false} element={<VendorListByFavorite />} />
                    <Route path="/contactus" caseSensitive={false} element={<Contactus />} />
                    <Route path="/eventscalendar" caseSensitive={false} element={<EventCalendar />} />
                    <Route path="/event_details" caseSensitive={false} element={<EventDetails />} />
                    <Route path="/login_dashboard" caseSensitive={false} element={<LoginDashboard />} />
                    <Route path="/Coming_soon" caseSensitive={false} element={<ComingSoon />} />
                    <Route path="/virtual_showrooms" caseSensitive={false} element={<VirtualShowrooms />} />
                    <Route path="/editor_board" caseSensitive={false} element={<Editor_Board />} />
                    <Route path="/*" caseSensitive={false} element={<Navigate to="/dashboard" replace />} />
                </Routes>
                
            </div>

        </>
    );
};

export default Main;