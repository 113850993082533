import {GET_CURRENT_PAGE, GET_CURRENT_PAGE_ERROR} from '../types'

const initialState = {
    currentPageName:[],
    loading:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_CURRENT_PAGE:
        return {
            ...state,
            currentPageName:action.payload,
            loading:false

        }
        case GET_CURRENT_PAGE_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}