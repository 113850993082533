import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import "../../assets/css/findinner.css"

const SubCategoryList = ({ categoryList, setSubMenu, isParent = false, tagIndex }) => {
    let navigate = useNavigate();

    const dispatch = useDispatch()
    return (
        <>
            <section class="find_category_section mt-3">
                <div class="myrow inner_container">
                    <div class="col_12">

                        <h3>{categoryList.name} :</h3>
                    </div>
                    <div class="col_12 category_div">
                        <ul class="seven_column">
                            
                            {
                                categoryList.children.map((item, index) => (
                            
                                    <li class="category_single cat_accessories">
                                        <a key={item.id}
                                         onClick={(e) => { 
                                            setSubMenu(item);
                                            dispatch(setBreadcurmbAction(item.name,()=>{
                                                dispatch(removeBreadcurmbAction(tagIndex));
                                                navigate(item.children?'/categories':'/vendor_list_category');
                                            },tagIndex));
                                                
                                            }}
                                            >
                                            <img src={item.image} alt={item.name}  />
                                            <h4>{item.name.replace("/", " / ")}</h4>
                                        </a>
                                    </li>

                                ))
                            }
                            
                        </ul>
                    </div>
                </div>
            </section>
            
        </>
    );
};

export default SubCategoryList;