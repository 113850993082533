
import React, { useEffect, useState } from 'react'
import "../../assets/css/Accessories.css"
import { isLogin as login } from '../../actions/isLoginAction'
import { vendorsListByCategoryAction } from '../../actions/vendorsListByCategoryAction'
import { getVendorDetailsAction } from '../../actions/getVendorDetailsAction'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';

import GoogleMapReact from 'google-map-react';
import Map_pin from "../Map_pin";
import { MarkFavoriteVendorAction } from '../../actions/MarkFavoriteVendorAction'
import BannerWithSearch from '../BannerWithSearch'
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction'
import VednorsList from './VednorsList'

const VendorListByCategory = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();


    useEffect(() => {
        dispatch(login(localStorage.getItem('token')))
        getVendors();
    }, [dispatch])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getVendors = ()=>{
        dispatch(vendorsListByCategoryAction(localStorage.getItem('token'), JSON.parse(localStorage.getItem('category'))))
    }

    const is_login = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = is_login
    if (loading == false && isLogin.status != 1) {
        navigate('/dashboard');
    }
    const vendorsListByCategory = useSelector(state => state.vendorsListByCategory)
    var { loading_vendorsListByCategoryData, vendorsListByCategoryData, category } = vendorsListByCategory

    

    const vendorDetailsClick = (vendorDetails) => {
        localStorage.setItem('vendorDetails', JSON.stringify(vendorDetails));
        dispatch(getVendorDetailsAction(vendorDetails))
    }

    const [search, setSearch] = useState('');

    const markFavorite = (vendor_id, favorite) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('vendor_id', vendor_id);
        formData.append('favorite', favorite);
        MarkFavoriteVendorAction(token, formData)
            .then((result) => {
                getVendors();

            }).catch((err) => {

            });
    }


    return (
        <div class="rightside">
            <BannerWithSearch />

            {!loading && isLogin.status == 1 ?
                    !loading_vendorsListByCategoryData && vendorsListByCategoryData.status == 1 ?
                        <VednorsList vendors_list={vendorsListByCategoryData.response.vendors} category={category} />
                        
                        :
                        <section class="loaction_section mr-3">
                            <div class="row inner_row find_row">
                                <div class="col-xs-12 col-sm-12 col-md-12 mb-5 search-bar-right-align">
                                    <div class="Accessories_heading">
                                        <div class="Accessories_heading_img" >
                                            <img src={JSON.parse(localStorage.getItem("category")).image} width="50" height="50" alt="" />
                                        </div>
                                        <div class="Accessories_heading_text">
                                            <h3>{JSON.parse(localStorage.getItem("category")).name}</h3>
                                            <p>0 Vendors Found in {JSON.parse(localStorage.getItem("category")).name} Listing</p>
                                        </div>
                                    </div>
                                    <div class="search_bar">
                                        <form action="/action_page.php">
                                            <input type="text" name="search" placeholder="Find" class="input_field" />
                                            <input type="submit" class="submit_button" />
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </section>
                        :null
            }
        </div>

    );
};


<style>

</style>
export default VendorListByCategory;