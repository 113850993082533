import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveTagActions } from '../../actions/activeTagActions';
import { getPage as get_page } from '../../actions/getPageAction'
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../../actions/subMenu1Actions';
import { getSubMenu2 } from '../../actions/subMenu2Actions';
import { closeSidebar } from '../Functions';

const MenuItems = ({itemList}) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const updatCategoryDispatch = (name)=>{
        dispatch(getActiveTagActions(name))
        dispatch(getSubMenu1([]));
        dispatch(getSubMenu2([]));
        dispatch(removeBreadcurmbAction(1));
    }
    return (
        <ul class="nav-links">
            {
                itemList.map((item, index) => (
                    <>
                        {
                            <li>
                                <Link to={item.to}

                                    onClick={() => {
                                        if (item.slug != null) {
                                            dispatch(get_page(localStorage.getItem('token'), item.slug))
                                            localStorage.setItem('slug', item.slug);
                                        }
                                        // if(item.clickAction){
                                        //     item.clickAction();
                                        // }
                                        updatCategoryDispatch(item.tag);
                                        dispatch(setBreadcurmbAction(item.breadcrumbTitle, () => {
                                            updatCategoryDispatch(item.tag);
                                            navigate(item.to);
                                        }, 1));
                                        closeSidebar()

                                    }}
                                >
                                    <img src={item.img} alt={item.name} />
                                    <span class="links_name">{item.name}</span>
                                </Link>
                            </li>
                        }

                    </>
                ))
            }

        </ul>
    );
};

export default MenuItems;