import React from 'react';
import '../../assets/css/login.css';
import '../../assets/css/register.css';

import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import $ from "jquery";


const Header1 = () => {
    return (
        <>
            {/* <!-- Modal --> */}
            {/* <ForgotPasswordForm />
            <LoginForm />
            <RegistrationForm /> */}
            <header class="header">
                <div class="myrow login_signup ">
                    <div class="left_side">
                        <div class="mobile_narbar" onClick={() => {
                            $(".sidebar").toggleClass("active-s");
                        }}>
                            <span class="dropdown-toggle mobile_narbar">|||</span>
                        </div>
                    </div>
                    {/* <div class="right_btn">
                        <a href="#" class="same_btn register mr-2" data-bs-toggle="modal" data-bs-target="#registerFormModal">Register</a>
                        <a href="#" class="same_btn login" data-bs-toggle="modal" data-bs-target="#loginFormModal">Login</a>
                    </div> */}
                </div>
            </header>
        </>
    );
};

export default Header1;