import React from 'react';
import { useState } from 'react';

const Map_pin = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    return (
        <div onMouseOver={() => { setShowDetails(true) }} onMouseOut={() => { setShowDetails(false) }}

        >
            <div className='popup-box' hidden={!showDetails} style={{
                color: 'black',
                backgroundColor: "white",
                width: "max-content",
                height: "max-content",
                padding: 10,
                fontSize: 14,
                fontWeight: "bold",
                borderRadius: 4,
                marginBottom: 10,
                zIndex: "9999",
                position: "absolute",
                bottom: 0,
            }}>
                {props.details.name}
                <div style={{fontSize:10}}>
                    <span>lat: {props.details.location.latitude}</span><br/>
                    <span>long: {props.details.location.longitude}</span>
                </div>
            </div>

            {/* // <div role="dialog" tabindex="-1" class="gm-style-iw gm-style-iw-c" aria-label="Akita Advocates" 
                // style={{paddingRight: 0, paddingBottom: 0, maxWidth: 446, maxHeight: 260, minWidth: 0}}>
                //     <div class="gm-style-iw-d" style={{overflow: "scroll", maxHeight: 242}}><div dir="ltr" jstcache="0"><div jstcache="34" class="poi-info-window gm-style"> 
                // <div jstcache="2">
                //      <div jstcache="3" class="title full-width" jsan="7.title,7.full-width">Akita Advocates</div> 
                //      <div class="address"> 
                //      <div jstcache="4" jsinstance="0" class="address-line full-width" jsan="7.address-line,7.full-width">14202 N Scottsdale Rd</div>
                //      <div jstcache="4" jsinstance="*1" class="address-line full-width" jsan="7.address-line,7.full-width">Scottsdale, AZ 85254</div> 
                //      </div>
                //       </div>
                //        <div class="view-link">
                //          <a target="_blank" jstcache="6" href="https://maps.google.com/maps?ll=33.6165,-111.926949&amp;z=20&amp;t=m&amp;hl=en-US&amp;gl=US&amp;mapclient=apiv3&amp;cid=6836527088725667619" tabindex="0"> <span> View on Google Maps </span>
                //           </a> 
                //           </div>
                //            </div>
                //            </div>
                //            </div>
                //            </div> */}
            <div >

                <img src="images/map_pin.svg" />
            </div>
        </div>
    );
};

export default Map_pin;