
import React, { useEffect, useState } from 'react'
import "../../assets/css/Accessories.css"
import { isLogin as login } from '../../actions/isLoginAction'
import { getFavoriteVendorsAction } from '../../actions/getFavoriteVendorsAction'
import { getVendorDetailsAction } from '../../actions/getVendorDetailsAction'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';

import GoogleMapReact from 'google-map-react';
import Map_pin from "../Map_pin";
import BannerWithSearch from '../BannerWithSearch'
import MenuButtons from '../Dashboard/MenuButtons'
import VednorsList from './VednorsList'

const VendorListByCategory = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(login(localStorage.getItem('token')))
        dispatch(getFavoriteVendorsAction(localStorage.getItem('token')))
    }, [dispatch])
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const is_login = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = is_login

    const getFavoriteVendors = useSelector(state => state.getFavoriteVendors)
    const { loading_favoriteVendors, favoriteVendors} = getFavoriteVendors

    if (loading == false && isLogin.status != 1) {
        navigate('/dashboard');
    }

    const vendorDetailsClick = (vendorDetails) => {
        vendorDetails.favorite = 1;
        localStorage.setItem('vendorDetails', JSON.stringify(vendorDetails));
        dispatch(getVendorDetailsAction(vendorDetails))


    }

    const [search,setSearch] = useState('');


    return (
        <div class="rightside">
            <BannerWithSearch />
            {
                !loading && isLogin.status == 1 ?
                    !loading_favoriteVendors && favoriteVendors.status == 1 && favoriteVendors.response.favorites.length > 0?
                    <VednorsList vendors_list={favoriteVendors.response.favorites} category={JSON.stringify({"id":13,"name":"Favorites","image":"/images/Favorites.svg","parent_id":12})} />    
                    // <section class="loaction_section mr-3">
                    //         <div class="row inner_row find_row">
                    //             <div class="col-xs-12 col-sm-12 col-md-12 mb-5 search-bar-right-align">
                    //                 <div class="Accessories_heading">
                    //                     <div class="Accessories_heading_img" >
                    //                         <img src="images/Favorites.svg" width="50" height="50" alt="" />
                    //                     </div>
                    //                     <div class="Accessories_heading_text">
                    //                         <h3>Favorites</h3>
                    //                         <p>{favoriteVendors.response.favorites.length} Vendors Found in Favorites Listing</p>
                    //                     </div>
                    //                 </div>
                    //                 <div class="search_bar">
                    //                     <form  onSubmit={(e)=>{e.preventDefault();}}>
                    //                         <input type="text" name="search" placeholder="Find" class="input_field" 
                    //                             value={search}
                    //                             onChange={(e)=>{setSearch(e.target.value)}} 
                    //                         />
                    //                         <input class="submit_button" />
                    //                     </form>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //         <div class="row inner_row location_row">
                    //             <div class="col-xs-6 col-sm-6 col-md-6 mb-4 mr-4">
                    //                 {
                                        
                    //                     favoriteVendors.response.favorites
                    //                     .filter((item,index)=>{
                    //                         return item.name.toLowerCase().includes(search.toLowerCase());
                    //                     })
                    //                     .map((item, index) => (
                    //                         <div class="single_location_div col-xs-6 col-sm-6 col-md-6">
                    //                             <div id="holder">
                    //                                 <div class={`box ${item.favorite == 1 ? "entypo-thumbs-down":"entypo-thumbs-up"}`}>Like</div>
                    //                                 <div class="tb"><a ><img src="images/tb.svg" /></a></div>
                    //                             </div>
                    //                             <div class="location_company_logo">
                    //                                 <img src={item.logo} alt={item.name} width="150" height="100" />
                    //                             </div>
                    //                             <div class="location_company_name">
                    //                                 <h3>{item.name}</h3>
                    //                                 <p><span class="address">{item.address_1}</span>
                    //                                     <span class="num">
                    //                                         <a href={item.phone} target="blank">{item.phone}</a>
                    //                                     </span>
                    //                                     <span class="email">
                    //                                         <a href={item.email} target="blank">{item.email}</a>
                    //                                     </span>
                    //                                     <span class="website">
                    //                                         <a href={item.website} target="blank">{item.website}</a>
                    //                                     </span>
                    //                                 </p>
                    //                                 <div class="btn_wapper">
                    //                                     <Link to="/vendor_details" class="same_btn" onClick={() => { vendorDetailsClick(item) }}>View Details</Link>
                    //                                 </div>
                    //                             </div>
                    //                         </div>

                    //                     ))
                    //                 }

                    //             </div>
                    //             <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                    //                 <div class="map_div">
                    //                     <div style={{ height: '100vh', width: '100%' }}>

                    //                         <GoogleMapReact
                    //                             bootstrapURLKeys={{ key: "AIzaSyDYrBj88ClZd1XVpJlpqOdth65ytLjvuTU" }}
                    //                             defaultCenter={{
                    //                                 lat: favoriteVendors.response.favorites[0].location.latitude,
                    //                                 lng: favoriteVendors.response.favorites[0].location.longitude
                    //                             }}
                    //                             defaultZoom={11}
                    //                         >
                    //                             {
                    //                                 favoriteVendors.response.favorites
                    //                                 .filter((item,index)=>{
                    //                                     return item.name.toLowerCase().includes(search.toLowerCase());
                    //                                 })
                    //                                 .map((item, index) => (
                    //                                     <Map_pin
                    //                                         lat={item.location.latitude}
                    //                                         lng={item.location.longitude}
                    //                                     />
                    //                                 ))
                    //                             }
                    //                         </GoogleMapReact>
                    //                     </div>
                                        
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </section>
                        :
                        <section class="loaction_section mr-3">
                            <div class="row inner_row find_row">
                                <div class="col-xs-12 col-sm-12 col-md-12 mb-5 search-bar-right-align">
                                    <div class="Accessories_heading">
                                        <div class="Accessories_heading_img" >
                                            <img src="images/Favorites.svg" width="50" height="50" alt="" />
                                        </div>
                                        <div class="Accessories_heading_text">
                                            <h3>Favorites</h3>
                                            <p>0 Vendors Found in Favorites Listing</p>
                                        </div>
                                    </div>
                                    <div class="search_bar">
                                        <form action="/action_page.php">
                                            <input type="text" name="search" placeholder="Find" class="input_field" />
                                            <input type="submit" class="submit_button" />
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </section>
                    : null
            }
            <MenuButtons />

        </div>

    );
};


<style>

</style>
export default VendorListByCategory;