import React, { useState } from 'react';
import { ContactusAction } from '../actions/ContactusAction';
import "../assets/css/contactus.css"
import ReCAPTCHA from "react-google-recaptcha";
import { recaptcha_site_key } from '../constants';
import BannerWithSearch from './BannerWithSearch';
import MenuButtons from './Dashboard/MenuButtons';

const Contactus = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");
    const [reCaptcha, setReCaptcha] = useState("");
    const [error, setErrors] = useState({
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        companyNameError: "",
        messageError: "",
    });

    const emptyAllFields = () => {

        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setCompanyName("");
        setMessage("");
        // setReCaptcha("");
    }


    const formSubmit = (e) => {


        formValidate().then((result) => {

            if (result) {
                const formData = new FormData();


                formData.append('first_name', firstName);
                formData.append('last_name', lastName);
                formData.append('phone_number', phoneNumber);
                formData.append('email', email);
                formData.append('company_name', companyName);
                formData.append('message', message);
                formData.append('reCaptcha', reCaptcha);




                // Details of the uploaded file
                ContactusAction(formData)
                    .then(res => {
                        const data = res.data;

                        alert(data.response.message)
                        emptyAllFields();
                        
                    })
                    .catch(error => {
                        console.log(error);
                    })
            } 
            

        })

    }


    const formValidate = async () => {

        return new Promise((resolve, reject) => {
            let status = true;
            if (!/^[A-Za-z ]+$/.test(firstName)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    firstNameError: "Invalid first name"
                }))
            }
            if (!/^[A-Za-z ]+$/.test(lastName)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    lastNameError: "Invalid last name"
                }))
            }
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    emailError: "Invalid email"
                }))
            }
            if (!/^[0-9 ()-]*$/.test(phoneNumber)) {
                status = false;
                setErrors(error => ({
                    ...error,
                    phoneNumberError: "Invalid phone number"
                }))
            }
            resolve(status);

        });
    }

    const onChangeReCaptcha = (value) => {
        if (value == null) {
            setReCaptcha('');

        }
        else {
            setReCaptcha(value);
        }
    }

    return (
        <div class="rightside">
            <BannerWithSearch />
            <section class="contact_section">
                <div class="row inner_row icon_section mb-4">
                    <div class="col-xs-6 col-sm-6 col-md-6 padding-0-10">
                        <h3>Contact Us:</h3>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 padding-0-10">
                        <ul class="social_icon">
                            <li class="facebook"><a href="https://www.facebook.com/tradefindry/" target="blank">facebook</a></li>
                            <li class="insta"><a href="https://www.instagram.com/trade_findry/" target="blank">insta</a></li>
                            <li class="linkedin"><a href="#" target="blank">linkedin</a></li>
                        </ul>
                    </div>
                </div>
                <form onSubmit={(e) => { e.preventDefault(); formSubmit(); }}>
                    <div class="row inner_row form_area">
                        <div class="col-xs-3 col-sm-3 col-md-3 mb-4">
                            <div class="form-group">
                                <label class="custom-control-label" for="First Name">First Name<span class="red_dot">*</span></label>
                                <input type="text" name="first_name" class="form-control input_user" value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)
                                        setErrors(error => ({
                                            ...error,
                                            firstNameError: ""
                                        }))
                                    }}
                                    required
                                />
                                <span style={{ color: "red", fontSize: 12 }}>{error.firstNameError}</span>
                            </div>
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-3 mb-4">
                            <div class="form-group">
                                <label class="custom-control-label" for="Last Name">Last Name<span class="red_dot">*</span></label>
                                <input type="text" name="last_name" class="form-control input_user" value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)
                                        setErrors(error => ({
                                            ...error,
                                            lastNameError: ""
                                        }))
                                    }}
                                    required
                                />
                                <span style={{ color: "red", fontSize: 12 }}>{error.lastNameError}</span>

                            </div>
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-3 mb-4">
                            <div class="form-group">
                                <label class="custom-control-label" for="Email Address">Email Address<span class="red_dot">*</span></label>
                                <input type="text" name="email_address" class="form-control input_user" value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                        setErrors(error => ({
                                            ...error,
                                            emailError: ""
                                        }))
                                    }}
                                    required
                                />
                                <span style={{ color: "red", fontSize: 12 }}>{error.emailError}</span>

                            </div>
                        </div>
                        <div class="col-xs-3 col-sm-3 col-md-3 mb-4">
                            <div class="form-group">
                                <label class="custom-control-label" for="Phone Number">Phone Number<span class="red_dot">*</span></label>
                                <input type="tel" name="phone_number" class="form-control input_user" value={phoneNumber}
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value)
                                        setErrors(error => ({
                                            ...error,
                                            phoneNumberError: ""
                                        }))
                                    }}
                                    required
                                />
                                <span style={{ color: "red", fontSize: 12 }}>{error.phoneNumberError}</span>
                            </div>

                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 mb-4">
                            <div class="form-group">
                                <label class="custom-control-label" for="Company Name">Company Name<span class="red_dot">*</span></label>
                                <input type="text" name="company_name" class="form-control input_user" value={companyName}
                                    onChange={(e) => { setCompanyName(e.target.value) }}
                                    required
                                />
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 mb-5">
                            <div class="form-group">
                                <label class="custom-control-label" for="Message">Message<span class="red_dot">*</span></label>
                                <textarea name="message" class="form-control input_user" value={message}
                                    onChange={(e) => { setMessage(e.target.value) }}
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 left_img_wapper padding-0-10">
                            {/* <img src="images/cap_img.jpg" /> */}
                            <ReCAPTCHA
                                sitekey={recaptcha_site_key}
                                onChange={onChangeReCaptcha}
                            />
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-6 right_btn_wapper padding-0-10">
                            <div class="form-group">
                                <button type="submit" disabled={reCaptcha == ""} name="button" class="btn same_btn login_btn"

                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <MenuButtons />
        </div>
    );
};

export default Contactus;