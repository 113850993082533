import React, { useEffect, useState, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import "../../assets/css/board_editor.css"
import $ from "jquery";
import { Rnd } from "react-rnd";
import { getProjectBoardActions } from '../../actions/getProjectBoardActions';
import * as htmlToImage from "html-to-image";
import { getShadeBoardsAction } from '../../actions/getShadeBoardsAction';
import { getUserGalleryActions } from '../../actions/getUserGalleryActions';
import { UpdateProjectBoardObjectsAction } from '../../actions/UpdateProjectBoardObjectsAction';
import ShareDesignBoard from './ShareDesignBoard';
import { SketchPicker } from 'react-color';
import { Cropper, getCroppedImg } from "react-cropper-custom";
import "react-cropper-custom/dist/index.css";
import { Button, Form, Modal } from 'react-bootstrap';
import { setCurrentPageAction } from '../../actions/setCurrentPageAction';


const EditorBoard = (Props) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(setCurrentPageAction("editor_board"))
        return () => {
            dispatch(setCurrentPageAction("other_pages"))
        }
    }, [dispatch])


    const contacts = JSON.parse(localStorage.getItem('contacts'));
    
    const project_id = localStorage.getItem('selectedProject');


    const fontSizes = [12, 14, 16, 18, 20, 22, 24]

    const [shadeBoards, setShadeBoards] = useState([]);
    const [userGallery, setUserGallery] = useState([]);


    

    const [projectDetails, setProjectDetails] = useState({});


    const [selectedObjectIndex, setSelectedObjectIndex] = useState();
    const [selectedObjectData, setSelectedObjectData] = useState({});


    const [gallery_seletecd_images, setGallery_seletecd_images] = useState([]);

    const [screenShot, setScreenShot] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [pickerColor, setPickerColor] = useState("000000");
    const [showCoprImage, setShowCoprImage] = useState(false);


    const [cropedImg, setCropedImg] = useState("");
    const [zoom, setZoom] = useState(0);

    const [showNoteModal, setShowNoteModal] = useState(false);
    const [galleryModal, setGalleryModal] = useState(false);




    const onCropComplete = async (croppedArea, image_url) => {
        try {
            const image = await getCroppedImg(image_url, croppedArea);
            setCropedImg(image);
        } catch (e) {
            console.error(e);
        }
    };

    const saveCroppedImage = () => {
        const updateObject = projectDetails;
        updateObject.data.data_array[selectedObjectIndex].project_board_object_id = -1;
        updateObject.data.data_array[selectedObjectIndex].data.img_url = cropedImg;
        updateObject.data.data_array[selectedObjectIndex].data.cropped = true;
        setProjectDetails(projectDetails => ({
            ...projectDetails,
            ...updateObject
        }))
    }

    const isLoginData = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = isLoginData


    const getProjectBoardId = useSelector(state => state.getProjectBoardId)
    const { project_board_id } = getProjectBoardId

    function getProjectBoard() {
        getProjectBoardActions(localStorage.getItem('token'), project_board_id == 0 ? project_id : project_board_id)
            .then((result) => {
                setProjectDetails(result.response.project);
                
            })
            .catch(error => {
                console.log(error);
            })
    }
    useEffect(() => {

        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            navigate('/dashboard');
        }

        
        getProjectBoard();
        
        getshadeBoards();
        getUserGallery();
        
        window.scrollTo(0, 0)
    }, [getProjectBoardId])


    useEffect(() => {
        setShadeBoard(projectDetails.shade_board_id)
    })

    const getshadeBoards = () => {
        getShadeBoardsAction(localStorage.getItem('token'))
            .then((result) => {
                if (result.status == 1) {
                    setShadeBoards(result.response.shade_boards);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const getUserGallery = () => {
        getUserGalleryActions(localStorage.getItem('token'))
            .then((result) => {
                if (result.status == 1) {
                    setUserGallery(result.response.gallery);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const projectBoardRef = createRef(null)
    

    const takeScreenShot = async (node) => {
        
        try {
            const dataURI = await htmlToImage.toBlob(node, {
                allowTaint: true,
                useCORS: true,
            });
            dataURI.lastModifiedDate = new Date();
            dataURI.name = "thumb.jpg";
            setScreenShot(dataURI)

            return dataURI;
        } catch (error) {
            console.log(error);
        }


    };

    const updateProjectShadeBoard = (shadeBoard) => {
        setProjectDetails(projectDetails => ({
            ...projectDetails,
            shade_board_id: shadeBoard.id
        }))
    }

    const [selectedShadeBoard, setSelectedShadeBoard] = useState({})

    const setShadeBoard = (shadeBoard_id) => {
        shadeBoards.map(item => {
            if (item.id == shadeBoard_id) {
                setSelectedShadeBoard(item)
            };
        })
    }

    const isSelectedImage = (id) => {
        var isTrue = false;
        gallery_seletecd_images.map((item, index) => {
            if (item.id == id) isTrue = true
        })
        return isTrue;
    }

    const addNewImageOnBoard = () => {
        const updateObject = projectDetails;
        gallery_seletecd_images.map((userGalleryIamge, index) => {
            updateObject.data.data_array = [
                ...updateObject.data.data_array,
                {
                    project_board_object_id: -1,
                    type: 1,
                    data: {
                        flip: { x: 1, y: 1 },
                        img_url: userGalleryIamge.image,
                        latitude: 0,
                        longitude: 0,
                        position: { x: 1, y: 1 },
                        size: { w: 200, h: 200 },
                        text: null,
                        text_style: { txt_color: 'black', txt_size: 14 },
                        zindex: 2,
                    },

                }
            ]

        })


        setProjectDetails(projectDetails => ({
            ...projectDetails,
            ...updateObject
        }))

        setGallery_seletecd_images([]);

        $(".fade").click()



    }

    const urlToImage = async (url) => {

        const file = await fetch(url);
        const theBlob = await file.blob();
        theBlob.lastModifiedDate = new Date();
        theBlob.name = "file_name.jpg";

        return theBlob;

        
    }
    const updateProjectBoardObjects = async (action) => {
        setIsLoading(true)
        setSelectedObjectIndex(-1)
        const formData = new FormData();

        takeScreenShot(projectBoardRef.current)
            .then(async (screenShot) => {
        
                var results = await Promise.all(projectDetails.data.data_array.map(async (item, index) => {
                    let imageFile = await urlToImage(item.data.img_url)
                    formData.append(index, imageFile, "fileName.jpg")
                    item.obj_type = item.type;
        
                    if (typeof (item.data.cropped) != 'undefined' && item.data.cropped) {
                        item.data.img_url = '0'
        
                        delete item.data.cropped;
                    }
                    return item;
                }))

        
                formData.append('project_id', projectDetails.data.project_id);
                formData.append('latitude', 0);
                formData.append('longitude', 0);
                formData.append('shade_board_id', selectedShadeBoard.id);
                formData.append('data_array', JSON.stringify(results));
                formData.append('description', projectDetails.description);
                formData.append('thumb', screenShot, "thumb.jpg");

                UpdateProjectBoardObjectsAction(localStorage.getItem('token'), formData)
                    .then(async (res) => {
                        await getProjectBoard();
                        await getUserGallery();
                        const data = res.data;
                        console.log(data);
                        
                        if (action != "share") {
                            alert(data.response.message);
                        }
                        if (action == "save_close") {
                            navigate('/project_boards');
                        }
                    }).then(() => {
                        setIsLoading(false)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(error => {
                console.log(error);
            })

    }


    const addTextObjectOnBoard = () => {
        // {
        //     "text": "Aroma\nThibault",
        //     "text_style": { "txt_color": "#ff004d", "txt_size": 22 }, 
        //     "img_url": null,
        //     "position": { "x": 145, "y": 95.66665649414062 }, 
        //     "size": { "w": 40, "h": 40 }, 
        //     "flip": { "x": 1, "y": 1 }, 
        //     "zindex": 4
        // }
        const updateObjects = projectDetails;
        updateObjects.data.data_array = [
            ...updateObjects.data.data_array,
            {
                project_board_object_id: -1,
                type: 2,
                data: {
                    "text": "",
                    "text_style": { "txt_color": "#000000", "txt_size": 22 },
                    "img_url": null,
                    "position": { "x": 1, "y": 1 },
                    "size": { "w": 200, "h": 50 },
                    "flip": { "x": 1, "y": 1 },
                    "zindex": 4
                },

            }
        ]
        setProjectDetails(projectDetails => ({
            ...projectDetails,
            ...updateObjects
        }))
    }

    const deleteObjectFromBoard = (object_index) => {
        var updateObjects = projectDetails;
        const new_data_array = updateObjects.data.data_array.filter((item, index) => {
            return index != object_index
        })
        updateObjects.data = {
            ...updateObjects.data,
            'data_array': new_data_array
        }

        setProjectDetails(projectDetails => ({
            ...projectDetails,
            ...updateObjects
        }))
    }
    return (
        <>

            <ShareDesignBoard contacts={contacts} />
            <header class="header">
                {
                    isLoading ?
                        <div class="loader-wrapper">
                            <div class="loader"></div>
                        </div>
                        : null
                }

                <div class="editor_board_header loggend_header">
                    <div class="left_side">
                        <div class="mobile_narbar">
                            <span class="dropdown-toggle mobile_narbar">|||</span>
                        </div>
                    </div>
                    <div class="editor_menu">

                        <div class="left_side_drop_down">

                            <div class="drop_down">
                                <ul>
                                    <li>Theme</li>
                                    <li><img src={selectedShadeBoard.image} style={{ width: 20, height: 20 }} alt="square image" /></li>
                                    <li>{selectedShadeBoard.name}</li>
                                    <li>
                                        <a class="dropdown-toggle"><img src="images/Polygon.png" alt="polygon image" /></a>
                                        <ul class="dropdown inner_dropdown" >
                                            {
                                                shadeBoards.map((shadeBoard, shadeBoard_index) => (
                                                    <li style={{ cursor: "pointer" }}
                                                        onMouseEnter={() => {
                                                            updateProjectShadeBoard(shadeBoard)
                                                            setShadeBoard(shadeBoard.id);
                                                        }}
                                                    ><img src={shadeBoard.image} alt={shadeBoard.name} style={{ width: 20, height: 20 }} /><span>{shadeBoard.name}</span></li>

                                                ))
                                            }

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="menu_icons">
                                <div class="edit_post_waper">
                                    <a class="dropdown-toggle">...</a>
                                    <ul class="dropdown">
                                        <li onClick={() => { setGalleryModal(true) }}>
                                            <img src="images/photos.png" alt="Photos icon" />
                                            {/* <span>Photos</span> */}
                                            <span data-bs-toggle="modal" data-bs-target="#userGallerModal"><a href="#">Photos</a></span>
                                        </li>
                                        <li onClick={() => { addTextObjectOnBoard() }}>
                                            <img src="images/text.png" alt="Text icon" />
                                            <span><a href="#">Text</a></span>
                                        </li>

                                        <li onClick={() => { setShowNoteModal(true) }}>
                                            <img src="images/notes.png" alt="Notes icon" />
                                            <span><a href="#">Notes</a></span>
                                        </li>
                                        <li onClick={() => { deleteObjectFromBoard(selectedObjectIndex) }}>
                                            <img src="images/delete.png" alt="Resize icon" />
                                            <span><a href="#">Delete</a></span>
                                        </li>
                                        <li>
                                            <img src="images/Share.png" alt="Notes icon" />
                                            <span><a data-bs-toggle="modal" data-bs-target="#shareDesignBoardModal"
                                                onClick={() => { updateProjectBoardObjects("share"); }}
                                            >Share</a></span>
                                        </li>
                                        <li>
                                            <img src="images/save.png" alt="Notes icon" />
                                            <span><a onClick={
                                                () => {
                                                    updateProjectBoardObjects("save");
                                                }}>Save</a></span>
                                        </li>
                                        <li>
                                            <img src="images/save.png" alt="Notes icon" />
                                            <span><a onClick={() => { updateProjectBoardObjects("save_close") }}>Save & Close</a></span>
                                        </li>

                                    </ul>
                                </div>
                                <ul class="mobile_hidden_s">
                                    {
                                        selectedObjectData.type == 1 ?

                                            <>
                                                <li>
                                                    <img src="images/adjust.png" alt="Adjust icon" />
                                                    <span data-bs-toggle="modal" data-bs-target="#cropImageModal" onClick={() => { setShowCoprImage(true) }}>Adjust</span>
                                                </li>

                                                <li className='flip_e_b'>
                                                    <img src="images/flip.png" alt="Flip icon" />
                                                    <span class="dropdown-toggle">Flip</span>
                                                    <ul style={{ left: "inherit", color: 'black' }} class="dropdown flip_d_d">
                                                        <li onClick={() => {
                                                            const updateObject = projectDetails;
                                                            updateObject.data.data_array[selectedObjectIndex].data.flip.x = selectedObjectData.data.flip.x == 1 ? -1 : 1;
                                                            setProjectDetails(projectDetails => ({
                                                                ...projectDetails,
                                                                ...updateObject
                                                            }))
                                                        }}>
                                                            Horizontal
                                                        </li>
                                                        <li onClick={() => {
                                                            const updateObject = projectDetails;
                                                            updateObject.data.data_array[selectedObjectIndex].data.flip.y = selectedObjectData.data.flip.y == 1 ? -1 : 1;
                                                            setProjectDetails(projectDetails => ({
                                                                ...projectDetails,
                                                                ...updateObject
                                                            }))
                                                        }}>
                                                            Vertical
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                            : null
                                    }

                                    {
                                        selectedObjectData.type == 2 ?
                                            <>
                                                <li class="color_picker_e_b" onClick={() => { setShowColorPicker(!showColorPicker) }}>
                                                    <img src="images/text_color.png" alt="Resize icon" />
                                                    <span>Color</span>
                                                    {
                                                        showColorPicker ?
                                                            <div style={{ position: "absolute", top: 40, right: -166 }}>

                                                                <SketchPicker
                                                                    color={pickerColor}
                                                                    onChange={(color) => {
                                                                        setPickerColor(color.hex);
                                                                        const updateObject = projectDetails;
                                                                        updateObject.data.data_array[selectedObjectIndex].data.text_style.txt_color = color.hex;
                                                                        setProjectDetails(projectDetails => ({
                                                                            ...projectDetails,
                                                                            ...updateObject
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                            : null
                                                    }

                                                </li>
                                                <li>
                                                    <img src="images/text_size.png" alt="Resize icon" />
                                                    <span class="dropdown-toggle">Font Size</span>
                                                    <ul style={{ left: "inherit", color: 'black' }} class="dropdown font_size_d_d">
                                                        {
                                                            fontSizes.map((item) => (
                                                                <li className='font_size_d_d_li'
                                                                    onClick={() => {
                                                                        const updateObject = projectDetails;
                                                                        updateObject.data.data_array[selectedObjectIndex].data.text_style.txt_size = item;
                                                                        setProjectDetails(projectDetails => ({
                                                                            ...projectDetails,
                                                                            ...updateObject
                                                                        }))
                                                                    }}
                                                                >{item}</li>
                                                            ))
                                                        }
                                                        
                                                    </ul>
                                                    
                                                </li>
                                            </>
                                            : null
                                    }
                                </ul>
                                <ul class="mobile_hidden">
                                    <li onClick={() => { setGalleryModal(true) }}>
                                        <img src="images/photos.png" alt="Photos icon" />
                                        {/* <span>Photos</span> */}
                                        <span data-bs-toggle="modal" data-bs-target="#userGallerModal">Photos</span>
                                    </li>
                                    <li onClick={() => { addTextObjectOnBoard() }}>
                                        <img src="images/text.png" alt="Text icon" />
                                        <span>Text</span>
                                    </li>
                                    {
                                        selectedObjectData.type == 1 ?

                                            <>
                                                <li>
                                                    <img src="images/adjust.png" alt="Adjust icon" />
                                                    <span data-bs-toggle="modal" data-bs-target="#cropImageModal" onClick={() => { setShowCoprImage(true) }}>Adjust</span>
                                                </li>

                                                <li className='flip_e_b'>
                                                    <img src="images/flip.png" alt="Flip icon" />
                                                    <span class="dropdown-toggle">Flip</span>
                                                    <ul style={{ left: "inherit", color: 'black' }} class="dropdown flip_d_d">
                                                        <li onClick={() => {
                                                            const updateObject = projectDetails;
                                                            updateObject.data.data_array[selectedObjectIndex].data.flip.x = selectedObjectData.data.flip.x == 1 ? -1 : 1;
                                                            setProjectDetails(projectDetails => ({
                                                                ...projectDetails,
                                                                ...updateObject
                                                            }))
                                                        }}>
                                                            Horizontal
                                                        </li>
                                                        <li onClick={() => {
                                                            const updateObject = projectDetails;
                                                            updateObject.data.data_array[selectedObjectIndex].data.flip.y = selectedObjectData.data.flip.y == 1 ? -1 : 1;
                                                            setProjectDetails(projectDetails => ({
                                                                ...projectDetails,
                                                                ...updateObject
                                                            }))
                                                        }}>
                                                            Vertical
                                                        </li>
                                                    </ul>
                                                </li>
                                            </>
                                            : null
                                    }
                                    <li onClick={() => { setShowNoteModal(true) }}>
                                        <img src="images/notes.png" alt="Notes icon" />
                                        <span>Notes</span>
                                    </li>
                                    <li onClick={() => { deleteObjectFromBoard(selectedObjectIndex) }}>
                                        <img src="images/delete.png" alt="Resize icon" />
                                        <span>Delete</span>
                                    </li>
                                    {
                                        selectedObjectData.type == 2 ?
                                            <>
                                                <li class="color_picker_e_b" onClick={() => { setShowColorPicker(!showColorPicker) }}>
                                                    <img src="images/text_color.png" alt="Resize icon" />
                                                    <span>Color</span>
                                                    {
                                                        showColorPicker ?
                                                            <div style={{ position: "absolute", top: 40, right: -166 }}>

                                                                <SketchPicker
                                                                    color={pickerColor}
                                                                    onChange={(color) => {
                                                                        setPickerColor(color.hex);
                                                                        const updateObject = projectDetails;
                                                                        updateObject.data.data_array[selectedObjectIndex].data.text_style.txt_color = color.hex;
                                                                        setProjectDetails(projectDetails => ({
                                                                            ...projectDetails,
                                                                            ...updateObject
                                                                        }))
                                                                    }}
                                                                />
                                                            </div>
                                                            : null
                                                    }

                                                </li>
                                                <li>
                                                    <img src="images/text_size.png" alt="Resize icon" />
                                                    <span class="dropdown-toggle">Font Size</span>
                                                    <ul style={{ left: "inherit", color: 'black' }} class="dropdown font_size_d_d">
                                                        {
                                                            fontSizes.map((item) => (
                                                                <li className='font_size_d_d_li'
                                                                    onClick={() => {
                                                                        const updateObject = projectDetails;
                                                                        updateObject.data.data_array[selectedObjectIndex].data.text_style.txt_size = item;
                                                                        setProjectDetails(projectDetails => ({
                                                                            ...projectDetails,
                                                                            ...updateObject
                                                                        }))
                                                                    }}
                                                                >{item}</li>
                                                            ))
                                                        }
                                                        
                                                    </ul>
                                                    
                                                </li>
                                            </>
                                            : null
                                    }
                                </ul>
                            </div>

                        </div>

                        <div class="menu_button">
                            <ul>
                                <li><a data-bs-toggle="modal" data-bs-target="#shareDesignBoardModal"
                                    onClick={() => { updateProjectBoardObjects("share"); }}
                                >Share</a></li>
                                <li><a onClick={
                                    () => {
                                        updateProjectBoardObjects("save");
                                    }}>Save</a></li>
                                <li><a onClick={() => { updateProjectBoardObjects("save_close") }}>Save & Close</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </header>

            <div class="editor_rightside rightside mt-5 ">

                <section class="text_editor mr-3">
                    

                    <div class="text_editor_area"  >
                        <div class="board_detail">
                            <h2><strong>Board:</strong>{projectDetails.name}</h2>
                            {
                                projectDetails.client_name != "" || projectDetails.additional_client_name != "" ?
                                    <h2><strong>Clients:</strong>{projectDetails.client_name}
                                        {projectDetails.additional_client_name != "" && ","}
                                        {projectDetails.additional_client_name}</h2>
                                    : null
                            }
                            {
                                projectDetails.vendor_name != "" && projectDetails.vendor_name != null?
                                    < h2 > <strong>Vendor:</strong>{projectDetails.vendor_name}</h2>
                                    : null
                            }
                        </div>
                        <div className='editor_board_box' style={{
                            width: "100%",
                            height: "100%",

                        }}
                            ref={projectBoardRef}
                        >
                            <img src={selectedShadeBoard.image}
                                onClick={() => {
                                    setSelectedObjectIndex(-1);
                                    setSelectedObjectData({});
                                }}

                            />
                            {
                                projectDetails.data != undefined ?
                                    projectDetails.data.data_array.map((object, object_index) => (

                                        <Rnd

                                            bounds="parent"
                                            enableResizing={object.type == 1 ? true : true}
                                            size={{ width: object.data.size.w, height: object.data.size.h }}
                                            position={{ x: object.data.position.x, y: object.data.position.y }}
                                            onDragStart={(e, d) => {
                                                
                                            }}
                                            onDragStop={(e, d) => {
                                                const updateObject = projectDetails;
                                                updateObject.data.data_array[object_index].data.position.x = d.x;
                                                updateObject.data.data_array[object_index].data.position.y = d.y;//parseInt(ref.style.height.replace("px", ""));
                                                
                                                setProjectDetails(projectDetails => ({
                                                    ...projectDetails,
                                                    ...updateObject
                                                }))
                                                

                                            }}
                                            onResizeStop={(e, direction, ref, delta, position) => {
                                                

                                                const updateObject = projectDetails;
                                                updateObject.data.data_array[object_index].data.size.w = parseInt(ref.style.width.replace("px", ""));
                                                updateObject.data.data_array[object_index].data.size.h = parseInt(ref.style.height.replace("px", ""));
                                                
                                                setProjectDetails(projectDetails => ({
                                                    ...projectDetails,
                                                    ...updateObject
                                                }))
                                            }}
                                            onMouseDown={(e) => {
                                                setSelectedObjectIndex(object_index)
                                                setSelectedObjectData(object)
                                                setCropedImg(object.data.img_url);
                                            }}
                                        >
                                            {
                                                object.type == 1 ?
                                                    <img style={{
                                                        borderStyle: object_index == selectedObjectIndex ? "solid" : "",
                                                        transform: `scale(${object.data.flip.x},${object.data.flip.y})`,
                                                    }}
                                                        src={object.data.img_url} alt="Notes icon" />
                                                    :

                                                    <>

                                                        <textarea
                                                            type="text"
                                                            placeholder='input text here'
                                                            // value={object.data.text}
                                                            style={{
                                                                color: object.data.text_style.txt_color,
                                                                fontSize: object.data.text_style.txt_size,
                                                                backgroundColor: 'transparent',
                                                                width: "100%",
                                                                height: "100%"
                                                            }}
                                                            onChange={(e) => {
                                                                const updateObject = projectDetails;
                                                                updateObject.data.data_array[object_index].data.text = e.target.value;
                                                                setProjectDetails(projectDetails => ({
                                                                    ...projectDetails,
                                                                    ...updateObject
                                                                }))

                                                            }}
                                                        >{object.data.text}</textarea>
                                                    </>

                                            }

                                        </Rnd>
                                    ))
                                    : null
                            }

                        </div>
                    </div>

                </section>
            </div >

            {

                userGallery.length > 0 ?

                    <div class="photos_popup">
                        <div class="modal fade" id="userGallerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="container h-100">
                                            <div class="user_card">
                                                <div class="d-flex justify-content-center form_container show_bottom">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-xs-12 col-sm-12 col-md-12 mb-4">
                                                                <div class="form-group checkbox_style">
                                                                    <div class="chose_imagebox_inner">
                                                                        {
                                                                            userGallery.map((userGalleryIamge, userGalleryIamge_index) => (

                                                                                <div class="custom_checkbox" key={userGalleryIamge.id}
                                                                                    onClick={() => {
                                                                                        if (isSelectedImage(userGalleryIamge.id)) {
                                                                                            setGallery_seletecd_images(gallery_seletecd_images.filter((item => { return item.id != userGalleryIamge.id })))
                                                                                        } else {

                                                                                            setGallery_seletecd_images([
                                                                                                ...gallery_seletecd_images,
                                                                                                userGalleryIamge
                                                                                            ])

                                                                                        }

                                                                                    }}
                                                                                >
                                                                                    <span class="imgcover"><img src={userGalleryIamge.image} alt="gallery Image" /></span>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`imageBox${userGalleryIamge.id}`}
                                                                                        name="featured"
                                                                                        checked={isSelectedImage(userGalleryIamge.id)}
                                                                                    />
                                                                                    <label for="featured-1">
                                                                                        {/* {gallery_seletecd_images.length} */}
                                                                                    </label>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row flex_center done_button">
                                                            <div class="col-xs-12 col-sm-12 col-md-12">
                                                                <div class="form-group flex_center">
                                                                    <button type="button" name="button" class="btn login_btn" data-mdb-dismiss="modal"
                                                                        onClick={() => { addNewImageOnBoard() }}
                                                                    >Done</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Modal show={galleryModal}
                        size='md'
                        onHide={() => {
                            setGalleryModal(false);
                        }}
                    >
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Project details</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body className='p-3'>
                            <span style={{ color: "black" }}>The gallery is empty, use Trade Findry mobile application to upload images.</span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn_note m-0 me-2" style={{ width: 80 }} onClick={() => { setGalleryModal(false) }} >
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

            }

            {
                typeof (selectedObjectData.data) != 'undefined' ?
                    <Modal show={showCoprImage} onHide={() => {
                        setShowCoprImage(false);
                        setZoom(0)
                    }}>
                        <Cropper
                            src={selectedObjectData.data.img_url}
                            width={500}
                            height={500}
                            zoom={zoom}
                            onZoomChange={setZoom}
                            onCropComplete={(c) => { onCropComplete(c, selectedObjectData.data.img_url) }}
                        />
                        {/* <img className="croppedImg" src={img} alt="croppedImg" /> */}
                        <div className='d-flex px-5 my-2'>
                            <button className='btn same_btn me-2 mt-0' onClick={() => {
                                if (cropedImg != '') saveCroppedImage();
                                setZoom(0)
                                setShowCoprImage(false);
                                setCropedImg('')
                                // $(".fade").click();
                            }}>save changes</button>
                            <button className='btn same_btn ms-2 mt-0' onClick={() => {
                                setShowCoprImage(false);
                                setZoom(0)
                                // $(".fade").click();
                            }}>Cancel</button>
                        </div>
                    </Modal>
                    : null
            }

            {


                <Modal show={showNoteModal}
                    size='lg'
                    onHide={() => {
                        setShowNoteModal(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Project details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-3'>
                        <Form.Control as="textarea" rows={3} value={projectDetails.description}
                            onChange={(e) => {
                                const updateObject = projectDetails;
                                updateObject.description = e.target.value;
                                
                                setProjectDetails(projectDetails => ({
                                    ...projectDetails,
                                    ...updateObject
                                }))

                                
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn_note m-0 me-2" onClick={() => { setShowNoteModal(false) }} >
                            Close
                        </Button>
                        <Button className='btn_note m-0' onClick={() => {
                            
                            setShowNoteModal(false)

                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            }


        </>


    );
};




export default EditorBoard;