import {GET_SEARCH_DATA, GET_SEARCH_DATA_ERROR} from '../types'

const initialState = {
    searchData:[],
    loading_searchData:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_SEARCH_DATA:
        return {
            ...state,
            searchData:action.payload,  
            loading_searchData:false

        }
        case GET_SEARCH_DATA_ERROR:
            return{
                loading_searchData: false, 
                error: action.payload 
            }
        default: return state
    }

}