import React, { useEffect, useState } from 'react';
import { CreateProjectAction } from '../../actions/CreateProjectAction';
import { getShadeBoardsAction } from '../../actions/getShadeBoardsAction';
import $ from "jquery";
import { getProjectsActions } from '../../actions/getProjectsActions';
import { useDispatch } from 'react-redux';

const CreateProject = () => {
    const dispatch = useDispatch()


    const [shadeBoards, setShadeBoards] = useState([]);

    const [boardName, setBoardName] = useState("");
    const [client1Name, setClient1Name] = useState("");
    const [client1Email, setClient1Email] = useState("");
    const [client2Name, setClient2Name] = useState("");
    const [client2Email, setClient2Email] = useState("");
    const [vendorName, setVendorName] = useState("");
    const [vendorLocation, setVendorLocation] = useState("");
    const [projectNotes, setProjectNotes] = useState("");
    const [selectedShadeBoard, setSelectedShadeBoard] = useState(1);


    useEffect(()=>{
        
        getshadeBoards();
        
    },[])

    const getshadeBoards = ()=>{
        getShadeBoardsAction(localStorage.getItem('token'))
        .then((result)=>{
            if(result.status == 1){
                setShadeBoards(result.response.shade_boards);
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const resetFields = ()=>{
        setBoardName("");
        setClient1Name("");
        setClient1Email("");
        setClient2Name("");
        setClient2Email("");
        setVendorName("");
        setVendorLocation("");
        setProjectNotes("");
        setSelectedShadeBoard(1);


    }

    const createProjectBoard= (e)=>{
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', boardName);
        formData.append('client_name', client1Name);
        formData.append('contact', client1Email);
        formData.append('add_client_name', client2Name);
        formData.append('contact2', client2Email);
        formData.append('vendor_name', vendorName);
        

        formData.append('budget', 0);
        formData.append('description', projectNotes);
        formData.append('shade_board_id', selectedShadeBoard);
        formData.append('location', vendorLocation);
        formData.append('latitude', 0.00);
        formData.append('longitude', 0.00);

        CreateProjectAction(localStorage.getItem('token'),formData)
        .then((result)=>{
            alert(result.response.message)
            resetFields();
            $(".modal").click()
            dispatch(getProjectsActions(localStorage.getItem('token')))
            
        })
        .catch(error=>{
            console.log(error);
        })
    }

    return (
        <div class="project_form">
            <div class="modal show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container h-100">
                                <div class="d-flex justify-content-left h-100">
                                    <div class="user_card">
                                        <div class="text_wapper_inner justify-content-left">
                                            <h3>Add New Project Board</h3>
                                            <p>Create a Board for a project or to track products from a vendor </p>
                                        </div>
                                        <div class="d-flex justify-content-center form_container">
                                            <form onSubmit={createProjectBoard} >
                                                <div class="row">
                                                    <div class="col-xs-12 col-sm-12 col-md-12 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Board Name">Board Name
                                                                <span class="red_dot">*
                                                                </span>
                                                                {/* </span> */}
                                                            </label>
                                                            <input type="text" name="board_name" class="form-control input_user" value={boardName} required 
                                                                onChange={(e)=>{setBoardName(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Client 1 Name">Client 1 Name</label>
                                                            <input type="text" name="client_name_1" class="form-control input_user" value={client1Name} 
                                                            onChange={(e)=>{setClient1Name(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Client 1 Email Address">Client 1 Email Address</label>
                                                            <input type="email" name="client_email_address_1" class="form-control input_pass" value={client1Email} 
                                                            onChange={(e)=>{setClient1Email(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Client 1 Name">Client 2 Name</label>
                                                            <input type="text" name="client_name_2" class="form-control input_user" value={client2Name} 
                                                            onChange={(e)=>{setClient2Name(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Client 2 Email Address">Client 2 Email Address</label>
                                                            <input type="email" name="client_email_address_2" class="form-control input_pass" value={client2Email} 
                                                            onChange={(e)=>{setClient2Email(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Vendor Name">Vendor Name</label>
                                                            <input type="text" name="vendor_name" class="form-control input_user" value={vendorName} 
                                                            onChange={(e)=>{setVendorName(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                                        <div class="form-group">
                                                            <label class="custom-control-label" for="Vendor Rep or Location">Vendor Rep or Location</label>
                                                            <input type="text" name="vendor_rep_or_location" class="form-control input_pass" value={vendorLocation} 
                                                            onChange={(e)=>{setVendorLocation(e.target.value)}}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 mb-4">
                                                        <div class="form-group">
                                                            <label for="trade_professional">Project Notes</label>
                                                            <textarea name="Project Notes" id="project_notes" value={projectNotes}
                                                            onChange={(e)=>{setProjectNotes(e.target.value)}}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12 mb-4 chose_box">
                                                        <div class="form-group">
                                                            <label for="Choose a Background for your Board">Choose a Background for your Board:</label>
                                                            <div class="chose_box_inner">
                                                                
                                                                {
                                                                     shadeBoards.map((shadeBoard, index) => (
                                                                        
                                                                        <div class="custom_radio" 
                                                                        onClick={(e)=>{
                                                                            setSelectedShadeBoard(shadeBoard.id)
                                                                        }}
                                                                        >
                                                                            <span class="imgcover"><img
                                                                             src={shadeBoard.image} alt="Minimal Kitchen Design " /></span>
                                                                            <input 
                                                                            type="radio" 
                                                                            id="featured" 
                                                                            name={shadeBoard.id} 
                                                                            checked={selectedShadeBoard == shadeBoard.id?true:false} 
                                                                            /><label for="featured-1"></label>
                                                                        </div>
                                                                    ))
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xs-12 col-sm-12 col-md-12">
                                                        <div class="form-group">
                                                            <button type="submit" name="button" class="btn login_btn">Add Board</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default CreateProject;