import Moment from 'moment-timezone'
import { MarkFavoriteVendorAction } from '../actions/MarkFavoriteVendorAction';
import $ from "jquery";

export const getFormattedDateTime = (miliseconds, format) => {
    // console.log("miliseconds ==> ", miliseconds)
    // // miliseconds = miliseconds - 3600000 // remove daylight saving hour
    // console.log("TIME ZONEE Default 01", Moment(new Date(parseInt(miliseconds))).format(format));

    // console.log("TIME ZONEE Phoenix 02", Moment(new Date(parseInt(miliseconds))).tz('America/Phoenix').format(format));

    // console.log("TIME ZONEE Karachi 01", Moment(new Date(parseInt(miliseconds))).tz('Asia/Karachi').format(format));

    // console.log("For testing", Moment.tz(new Date(parseInt(miliseconds)), "Asia/Karachi").tz("Asia/Karachi").format(format));

    // console.log("For testing", Moment.tz(new Date(parseInt(miliseconds)), "UTC").tz("UTC").format(format));

    //return Moment(new Date(parseInt(miliseconds))).tz('America/Phoenix').format(format)
    //miliseconds = miliseconds - 3600000 // remove daylight saving hour
    // MM/DD/YYYY
    return Moment(new Date(parseInt(miliseconds))).tz('Asia/Karachi').format(format) //time from admin panel
};

export const getMarkedDates = (state) => {
    let marked_dates = []
    for (var key in state) {
        //years
        let year = key;
        state[key].forEach((monthElement, monthIndex) => {
            //month
            if (monthElement.length > 0) {
                let month = (monthIndex + 1) <= 9 ? ('0' + (monthIndex + 1)) : ('' + (monthIndex + 1))
                monthElement.forEach((dayElement, dayIndex) => {
                    //days
                    let day = (dayIndex + 1) <= 9 ? ('0' + (dayIndex + 1)) : ('' + (dayIndex + 1))

                    //concatenate date
                    if (dayElement.length > 0) {

                        let date = year + '-' + month + '-' + day
                        dayElement.forEach((eventElement, eventIndex) => {
                            marked_dates.push({
                                id:eventElement.id,
                                title:eventElement.event_name,
                                dayevent:eventElement.dayevent,
                                rsvp:eventElement.rsvp,
                                start: date,
                                date_time:eventElement.date_time,
                                end_date_time:eventElement.end_date_time,
                                backgroundColor:eventElement.dayevent?"day_wapper_event":"neight_wapper_event", // dayevent
                                // data: dayElement,
                                // color: dayElement[dayElement.length-1].dayevent ? Color.BRAND_YELLOW : Color.GREY_2,
                                // textColor: Color.BLACK_1,
                                // startingDay: true,
                                // endingDay: true
                            });
                        });
                    }
                })
            }
        });
    }
    return (marked_dates)
}

export function ifDateIsPassed(date) {
    return parseInt(date) > new Date().getTime() ? false : true;
}


export function addBreadcrumb(data) {
    
}

export function removeBreadcrumb(data) {
    
}

export function closeSidebar() {
    $(".sidebar").toggleClass("active-s");
}