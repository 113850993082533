import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveTagActions } from '../../actions/activeTagActions';
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../../actions/subMenu1Actions';
import { getSubMenu2 } from '../../actions/subMenu2Actions';
import { getPage as get_page } from '../../actions/getPageAction'

const MenuButtons = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();

    // useEffect(() => {
    //     dispatch(getActiveCategory(localStorage.getItem('token')))
    //     dispatch(setCurrentPageAction("login_dashboard"))
    // }, [dispatch])

    const setActiveTab = (tab) => {
        dispatch(getActiveTagActions(tab))
    }

    const activeTagReducer = useSelector(state => state.activeTag)
    const { activeTag } = activeTagReducer

    const updatCategoryDispatch = (name, tab, navigateURL) => {
        setActiveTab(tab);
        dispatch(setBreadcurmbAction(name, () => {
            dispatch(getActiveTagActions(tab))
            dispatch(getSubMenu1([]));
            dispatch(getSubMenu2([]));
            dispatch(removeBreadcurmbAction(1));
            navigate(navigateURL);
        }, 1));
        dispatch(removeBreadcurmbAction(1));
    }

    const getBreadcurmb = useSelector(state => state.getBreadcurmb)
    const { loading_breadcrumbsList, breadcrumbsList } = getBreadcurmb

    const menuButtonsList = [
        {
            name: "Find By Category",
            url: "/categories",
            tag: 'categories',
        },
        {
            name: "Project Boards",
            url: "/project_boards",
            tag: 'projectBoards',
        },
        {
            name: "Find By Map",
            url: "/find_by_map",
            tag: 'findByMap',
        },
        {
            name: "Events",
            url: "/eventscalendar",
            tag: 'events',
        },
        {
            name: "Favorites",
            url: "/vendor_list_favorite",
            tag: 'favorites',
        },
        {
            name: "What’s New",
            url: "/whats_new",
            tag: 'WhatsNew',
            onClick:()=>{
                dispatch(get_page(localStorage.getItem('token'),"whats-new_desktop"))
                localStorage.setItem('slug', "whats-new_desktop");
            }
        },
    ]

    const is_login = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = is_login
    return (
        isLogin.status == 1?
        <section class="ya_two_section list_logo_two  mt-8 mb-8">
            <div class="myrow row_flex">
                {menuButtonsList.map((menuButton, index) => (
                    <div class={`col_6  ${activeTag == menuButton.tag ? "active" : ''} ${breadcrumbsList.length > 1?"small-size-button":''}`} 
                    >
                        <Link to={menuButton.url} class="yb_rot"
                            onClick={() => {
                                if(menuButton.onClick){
                                    menuButton.onClick()
                                }
                                updatCategoryDispatch(menuButton.name, menuButton.tag, menuButton.url);
                            }}
                        >
                            <h3>{menuButton.name}</h3>
                        </Link>
                    </div>
                ))}
            </div>
        </section>
        :null
    );
};

export default MenuButtons;