import {GET_CATEGORIES, CATEGORIES_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import { is_login } from './is_login';


export const getCategories = () => async dispatch => {
    
    try{
        // console.log(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.CATEGORIES_BEFORE_LOGIN}`);
        // const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.CATEGORIES_BEFORE_LOGIN}`)
        const token = localStorage.getItem('token');
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.CATEGORIES:ROUTE.CATEGORIES_BEFORE_LOGIN}`;
        const res = await axios.get(API,config);
        
        dispatch( {
            type: GET_CATEGORIES,
            payload: res.data
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: CATEGORIES_ERROR,
            payload: error,
        })
    }

}

