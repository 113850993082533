import {GET_VENDORS_DETAILS, GET_VENDORS_DETAILS_ERROR} from '../types'

const initialState = {
    getVendorDetailsData:[],
    loading_getVendorDetails:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_VENDORS_DETAILS:
            // console.log(" in GET_VENDORS_DETAILS ",action);
        return {
            ...state,
            getVendorDetailsData:action.payload,  
            loading_getVendorDetails:false

        }
        case GET_VENDORS_DETAILS_ERROR:
            return{
                loading_getVendorDetails: false, 
                error: action.payload 
            }
        default: return state
    }

}