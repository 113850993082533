import {GET_BUSINESS_CATEGORIES, BUSINES_CATEGORIES_ERROR} from '../types'

const initialState = {
    business_categories:[],
    loading_business_categories:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_BUSINESS_CATEGORIES:
        return {
            ...state,
            business_categories:action.payload,
            loading_business_categories:false

        }
        case BUSINES_CATEGORIES_ERROR:
            return{
                loading_business_categories: false, 
                error: action.payload 
            }
        default: return state
    }

}