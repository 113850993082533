import {IS_LOGIN, IS_LOGIN_ERROR} from '../types'

const initialState = {
    isLogin:[],
    loading:true
}

export default function(state = initialState, action){

    switch(action.type){

        case IS_LOGIN:
        return {
            ...state,
            isLogin:action.payload,  
            loading:false

        }
        case IS_LOGIN_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}