import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const LoginAction = async (email, password, fcm_token) => {

    try {
        
        const body = {
            email,
            password,
            fcm_token
        }

        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.LOGIN}`,
            data: body,
        });

        const res = response.data;
        if(res.status == 1 ){
            localStorage.setItem('token', res.response.token);
        }



        return response;

        
    }
    catch (error) {
        console.log(error);
        return error;

        
    }

}


