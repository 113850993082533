
import React, { useEffect, useState } from 'react'
import "../../assets/css/virtualShowroom.css"
import { getVirtualShowroomActions } from '../../actions/getVirtualShowroomActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import BannerWithSearch from '../BannerWithSearch';



const VirtualShowrooms = () => {


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getVirtualShowroomActions(localStorage.getItem('token')))
    }, [dispatch])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getVirtualShowrooms = useSelector(state => state.getVirtualShowrooms)
    const { loading_virtual_showrooms, virtual_showrooms } = getVirtualShowrooms

    const [search, setSearch] = useState('');

    const is_loaded = !loading_virtual_showrooms && virtual_showrooms.status == 1;


    return (
        <div class="rightside">
            <BannerWithSearch />

            {
                <section class="loaction_section mr-3">
                    <div class="row inner_row find_row">
                        <div class="col-xs-12 col-sm-12 col-md-12 mb-5 search-bar-right-align">
                            <div class="Accessories_heading">
                                <div class="Accessories_heading_img" >
                                    <img src="images/VirtualShowrooms.svg" width="50" height="50" alt="" />
                                </div>
                                <div class="Accessories_heading_text">
                                    <h3>Virtual Showroom</h3>
                                    <p>{is_loaded ? virtual_showrooms.response.showrooms.length : 0} showroom Found in virtual showroom Listing</p>
                                </div>
                            </div>
                            <div class="search_bar">
                                <form onSubmit={(e) => { e.preventDefault(); }}>
                                    <input type="search" name="search" placeholder="Find" class="input_field"
                                        value={search}
                                        onChange={(e) => { setSearch(e.target.value) }}
                                    />
                                    <input class="submit_button" />
                                </form>
                            </div>
                        </div>
                    </div>
                    {
                        is_loaded ?
                            <div class="row inner_row location_row vs_row">
                                <div class="col-xs-6 col-sm-6 col-md-6 mb-4 mr-4">
                                    {
                                        virtual_showrooms.response.showrooms
                                            .filter((item, index) => {
                                                return item.name.toLowerCase().includes(search.toLowerCase());
                                            })
                                            .map((item, index) => (
                                                <div class="single_location_div col-xs-6 col-sm-6 col-md-6">
                                                    <div class="location_company_logo">
                                                        <img src={item.showroom_thumbnail} alt={item.name} width="150" height="100" />
                                                    </div>
                                                    <div class="location_company_logo small_logo">
                                                        <img src={item.logo} alt={item.name} />
                                                    </div>
                                                    <div class="location_company_name">
                                                        <h3><a href={item.virtual_showroom1} target="blank" style={{ color: "black" }}>{item.name}</a></h3>


                                                    </div>
                                                </div>

                                            ))
                                    }

                                </div>

                            </div>
                            : null
                    }
                </section>

            }
        </div>

    );
};


<style>

</style>
export default VirtualShowrooms;