import {IS_LOGIN, IS_LOGIN_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const isLogin = (token) => async dispatch => {
    
    try{
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.IS_LOGGEDIN}`;
        const res = await axios.get(API,config);
        dispatch( {
            type: IS_LOGIN,
            payload: res.data
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: IS_LOGIN_ERROR,
            payload: error,
        })
    }

}

