import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getVendorDetailsAction } from '../../actions/getVendorDetailsAction';
import { MarkFavoriteVendorAction } from '../../actions/MarkFavoriteVendorAction';
import { setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { vendorsListByCategoryAction } from '../../actions/vendorsListByCategoryAction';
import GoogleMapReact from 'google-map-react';
import Map_pin from "../Map_pin";
import { vendorsListByMapAction } from '../../actions/vendorsListByMapAction';


const VednorsList = ({ vendors_list, category }) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])

    const [search, setSearch] = useState('');
    const getVendors = ()=>{
        dispatch(vendorsListByCategoryAction(localStorage.getItem('token'), JSON.parse(localStorage.getItem('category'))))
        dispatch(vendorsListByMapAction(localStorage.getItem('token'), JSON.parse(localStorage.getItem('designCenter'))))
        
    }
    const markFavorite = (vendor_id, favorite) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('vendor_id', vendor_id);
        formData.append('favorite', favorite);
        MarkFavoriteVendorAction(token, formData)
            .then((result) => {
                getVendors();

            }).catch((err) => {

            });
    }

    const vendorDetailsClick = (vendorDetails) => {
        localStorage.setItem('vendorDetails', JSON.stringify(vendorDetails));
        dispatch(getVendorDetailsAction(vendorDetails))
    }
    return (
        <section class="loaction_section mr-3">
            <div class="row inner_row find_row">
                <div class="col-xs-12 col-sm-12 col-md-12 mb-5 search-bar-right-align">
                    <div class="Accessories_heading">
                        <div class="Accessories_heading_img" >
                            <img src={JSON.parse(category).image} width="50" height="50" alt="" />
                        </div>
                        <div class="Accessories_heading_text">
                            <h3>{JSON.parse(category).name}</h3>
                            <p>{vendors_list.length} Vendors Found in {JSON.parse(category).name} Listing</p>
                        </div>
                    </div>
                    <div class="search_bar">
                        <form onSubmit={(e) => { e.preventDefault(); }}>
                            <input type="text" name="search" placeholder="Find" class="input_field"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <input class="submit_button" />
                        </form>
                    </div>
                </div>
            </div>
            <div class="row inner_row location_row">
                <div class="col-xs-6 col-sm-6 col-md-6 mb-4 mr-4">
                    {
                        vendors_list
                            .filter((item, index) => {
                                return item.name.toLowerCase().includes(search.toLowerCase());
                            })
                            .map((item, index) => (
                                <div class="single_location_div col-xs-6 col-sm-6 col-md-6">
                                    <div id="holder">
                                        <div class={`box ${item.favorite == 1 ? "entypo-thumbs-down" : "entypo-thumbs-up"}`}
                                            onClick={() => {
                                                markFavorite(item.id, item.favorite == 1 ? 0 : 1);
                                            }}
                                        >Like</div>
                                        <div class="tb"><a ><img src={item.trade_program == 1 ? "images/to_26by26.png" : "images/tb.svg"} /></a></div>
                                    </div>
                                    <div class="location_company_logo">
                                        <img src={item.logo} alt={item.name} width="150" height="100" />
                                    </div>
                                    <div class="location_company_name">
                                        <h3>{item.name}</h3>
                                        <p><span class="address">{item.address_1}</span>
                                            <span class="num">
                                                <a href={item.phone} target="_blank">{item.phone}</a>
                                            </span>
                                            <span class="email">
                                                <a href={`mailto:${item.email}`} target="_blank">{item.email}</a>
                                            </span>
                                            <span class="website">
                                                <a href={item.website} target="_blank">{item.website}</a>
                                            </span>
                                        </p>
                                        <div class="btn_wapper">
                                            <Link to="/vendor_details" class="same_btn" onClick={() => {
                                                vendorDetailsClick(item);
                                                dispatch(setBreadcurmbAction(item.name, () => {
                                                    // console.log(`it working ${item.name}`)
                                                    // dispatch(getSubMenu2([]));
                                                    // dispatch(removeBreadcurmbAction(5));
                                                    // navigate('/login_dashboard');
                                                }, 5));
                                            }}>View Details</Link>
                                        </div>
                                    </div>
                                </div>

                            ))
                    }

                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                    <div class="map_div">
                        <div style={{ height: '100vh', width: '100%' }}>

                            <GoogleMapReact
                                bootstrapURLKeys={{ key: `${process.env.REACT_APP_MAP_KEY}`}}
                                defaultCenter={{
                                    lat: vendors_list[0].location.latitude,
                                    lng: vendors_list[0].location.longitude
                                }}
                                defaultZoom={11}
                            >
                                {
                                    vendors_list
                                        .filter((item, index) => {
                                            return item.name.toLowerCase().includes(search.toLowerCase());
                                        })
                                        .map((item, index) => (
                                            <Map_pin
                                                details = {item}
                                                lat={item.location.latitude}
                                                lng={item.location.longitude}
                                            // text="My Marker"
                                            />
                                        ))
                                }
                            </GoogleMapReact>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default VednorsList;