import {PROJECT_BOARD_ID, PROJECT_BOARD_ID_ERROR} from '../types'

const initialState = {
    project_board_id:0,
    project_board_id_loading:true
}

export default function(state = initialState, action){

    switch(action.type){

        case PROJECT_BOARD_ID:
        return {
            ...state,
            project_board_id:action.payload,
            project_board_id_loading:false

        }
        case PROJECT_BOARD_ID_ERROR:
            return{
                project_board_id_loading: false, 
                error: action.payload 
            }
        default: return state
    }

}