import { BREADCRUMB, BREADCRUMB_ERROR, REMOVE_BREADCRUMB, REMOVE_BREADCRUMB_ERROR, UPDATE_BREADCRUMB, UPDATE_BREADCRUMB_ERROR } from '../types'

const initialState = {
    breadcrumbsList: [],
    loading_breadcrumbsList: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case BREADCRUMB:

            {
                
                const new_breadcrumbsList = [...state.breadcrumbsList];
                new_breadcrumbsList[action.payload.index] = action.payload;
                
                return {
                    ...state,
                    breadcrumbsList: new_breadcrumbsList,
                    loading_designCentersData: false

                }
            }
        case BREADCRUMB_ERROR:
            return {
                loading_designCentersData: false,
                error: action.payload
            }

        case REMOVE_BREADCRUMB:
            {
                
                const new_breadcrumbsList = [...state.breadcrumbsList];
                
                new_breadcrumbsList.length = action.payload + 1;
                return {
                    ...state,
                    breadcrumbsList: new_breadcrumbsList,
                    loading_designCentersData: false

                }
            }
        case REMOVE_BREADCRUMB_ERROR:
            return {
                loading_designCentersData: false,
                error: action.payload
            }
        case UPDATE_BREADCRUMB:
            {
                
                return {
                    ...state,
                    breadcrumbsList: action.payload,
                    loading_designCentersData: false
                }
            }
        case UPDATE_BREADCRUMB_ERROR:
            return {
                loading_designCentersData: false,
                error: action.payload
            }


        default: return state
    }

}