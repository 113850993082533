import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import { is_login } from './is_login';

export const getEventsAction = async (token) => {

    try {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        const response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.EVENTS:ROUTE.EVENTS_BEFORE_LOGIN}`, config)
       
        return response.data;

    }
    catch (error) {
        console.log(error);
        return error;
    }

}


