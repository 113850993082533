import {GET_ACTIVE_CATEGORIES, ACTIVE_CATEGORIES_ERROR} from '../types'

const initialState = {
    activeCategory:0,
    loading:true
}

export default function(state = initialState, action){
    switch(action.type){
        
        case GET_ACTIVE_CATEGORIES:
            return {
            ...state,
            activeCategory:action.payload,
            loading:false

        }
        case ACTIVE_CATEGORIES_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}