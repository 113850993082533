import {GET_VENDORS_DETAILS, GET_VENDORS_DETAILS_ERROR} from '../types'


export const getVendorDetailsAction = (vendor_details) => async dispatch => {
    try{

        dispatch( {
            type: GET_VENDORS_DETAILS,
            payload: vendor_details
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: GET_VENDORS_DETAILS_ERROR,
            payload: error,
        })
    }

}

