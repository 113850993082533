import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ForgotPassword } from '../../actions/ForgotPasswordAction';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux'
import { isLogin } from '../../actions/isLoginAction'




const LoginForm = () => {
    const dispatch = useDispatch()
    // const usersList = useSelector(state => state.categoriesList)
    // const { loading, error, users } = usersList

    const [email, setEmail] = useState('');
    const [error_message, setError_message] = useState('');
    // const [isModal, setModal] = useState("true")
    let navigate = useNavigate();


    const forgotPassword = (e) => {
        // alert("error");
        ForgotPassword(email)
            .then(res => {
                const data = res.data;
                // console.log(res.data);
                if (data.status == 0) {
                    setError_message(res.data.response.message);
                } else {
                    $(".fade").click()
                    dispatch(isLogin(data.response.token))
                    navigate('/login_dashboard');
                }
            })
            .catch(error => {
                console.log(error);
            })
        e.preventDefault();
    }
    return (

        <div class="login_form">
            <div class="modal fade" id="forgotPasswordFormModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container h-100">
                                <div class="d-flex justify-content-center h-100">
                                    <div class="user_card">
                                        <div class="d-flex justify-content-center">
                                            <div class="brand_logo_container">
                                                <img src="images/logo.jpg" class="brand_logo" alt="Logo" />
                                            </div>
                                            <h3>Reset your<br /> TRADE FINDRY Account</h3>
                                        </div>
                                        <div class="d-flex justify-content-center form_container">
                                            <form onSubmit={(e) => { forgotPassword(e) }}>
                                                <div class="input-group mb-3">
                                                    <label class="custom-control-label" for="Email" >Email</label>
                                                    <input type="email" name="Email" class="form-control input_user" required value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            setError_message('');
                                                        }}

                                                    />
                                                </div>
                                                {/* <div class="input-group mb-2">
                                                    <label class="custom-control-label" for="Password">Password</label>
                                                    <input type="password" name="Password" class="form-control input_pass" required value={password}
                                                        onChange={(e) => {
                                                            setPassword(e.target.value);
                                                            setError_message('');

                                                        }} />
                                                </div> */}

                                                <div class="d-flex justify-content-center mt-3 login_container">
                                                    <button type="submit" name="button" class="btn login_btn">Send</button>
                                                </div>
                                            </form>
                                            <span className='red error_message'>{error_message}</span>
                                        </div>
                                        {/* <div class="mt-3">
                                            <div class="d-flex justify-content-center ">
                                                <a href="#" class="links">Forgot your password?</a>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                                </div> --> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;