import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const MarkFavoriteVendorAction = async (token, formData) => {

    try {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
        const response = await axios.post(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.MARK_FAVORIT}`, formData, config)
        return response;


    }
    catch (error) {
        console.log(error);
        return error;


    }

}


