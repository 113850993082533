export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_SUBMENU1 = 'GET_SUBMENU1';
export const SUBMENU1_ERROR = 'GET_SUBMENU1_ERROR';

export const GET_SUBMENU2 = 'GET_SUBMENU2';
export const SUBMENU2_ERROR = 'GET_SUBMENU2_ERROR';

export const GET_ACTIVE_CATEGORIES = 'GET_ACTIVE_CATEGORIES';
export const ACTIVE_CATEGORIES_ERROR = 'ACTIVE_CATEGORIES_ERROR';

export const GET_ACTIVE_SUB_CATEGORIES = 'GET_ACTIVE_SUB_CATEGORIES';
export const ACTIVE_SUB_CATEGORIES_ERROR = 'ACTIVE_SUB_CATEGORIES_ERROR';

export const GET_LOGIN = 'GET_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';


export const IS_LOGIN = 'IS_LOGIN';
export const IS_LOGIN_ERROR = 'IS_LOGIN_ERROR';

export const GET_PAGE = 'GET_PAGE';
export const GET_PAGE_ERROR = 'GET_PAGE_ERROR';

export const GET_DESIGN_CENTER = 'GET_DESIGN_CENTER';
export const GET_DESIGN_CENTER_ERROR = 'GET_DESIGN_CENTER_ERROR';

export const GET_VENDORS_LIST_MAP = 'GET_VENDORS_LIST_MAP';
export const GET_VENDORS_LIST_MAP_ERROR = 'GET_VENDORS_LIST_MAP_ERROR';

export const GET_VENDORS_DETAILS = 'GET_VENDORS_DETAILS';
export const GET_VENDORS_DETAILS_ERROR = 'GET_VENDORS_DETAILS_ERROR';


export const GET_VENDORS_LIST_CATEGORY = 'GET_VENDORS_LIST_CATEGORY';
export const GET_VENDORS_LIST_CATEGORY_ERROR = 'GET_VENDORS_LIST_CATEGORY_ERROR';

export const GET_FAVORITE = 'GET_FAVORITE';
export const GET_FAVORITE_ERROR = 'GET_FAVORITE_ERROR';

export const GET_STATIC_PAGE = 'GET_STATIC_PAGE';
export const GET_STATIC_PAGE_ERROR = 'GET_STATIC_PAGE_ERROR';

export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const GET_SEARCH_DATA_ERROR = 'GET_SEARCH_DATA_ERROR';

export const GET_EVENT_DETAILS = 'GET_EVENT_DETAILS';
export const GET_EVENT_DETAILS_ERROR = 'GET_EVENT_DETAILS_ERROR';

export const GET_BUSINESS_CATEGORIES = 'GET_BUSINESS_CATEGORIES';
export const BUSINES_CATEGORIES_ERROR = 'BUSINES_CATEGORIES_ERROR';

export const GET_VIRTUAL_SHOWROOMS = 'GET_VIRTUAL_SHOWROOMS';
export const VIRTUAL_SHOWROOMS_ERROR = 'VIRTUAL_SHOWROOMS_ERROR';

export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

export const GET_USER_GALLERY = 'GET_USER_GALLERY';
export const GET_USER_GALLERY_ERROR = 'GET_USER_GALLERY_ERROR';


export const GET_CURRENT_PAGE = 'GET_CURRENT_PAGE';
export const GET_CURRENT_PAGE_ERROR = 'GET_CURRENT_PAGE_ERROR';


export const PROJECT_BOARD_ID = 'PROJECT_BOARD_ID';
export const PROJECT_BOARD_ID_ERROR = 'PROJECT_BOARD_ID_ERROR';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';


export const BREADCRUMB = 'BREADCRUMB';
export const BREADCRUMB_ERROR = 'BREADCRUMB_ERROR';

export const REMOVE_BREADCRUMB = 'REMOVE_BREADCRUMB';
export const REMOVE_BREADCRUMB_ERROR = 'REMOVE_BREADCRUMB_ERROR';

export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB';
export const UPDATE_BREADCRUMB_ERROR = 'UPDATE_BREADCRUMB_ERROR';


export const GET_ACTIVE_TAG = 'GET_ACTIVE_TAG';
export const ACTIVE_TAG_ERROR = 'ACTIVE_TAG_ERROR';