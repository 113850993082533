import {GET_BUSINESS_CATEGORIES, BUSINES_CATEGORIES_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const getBusinessCategoryActions = () => async dispatch => {
    
    try{
        const res = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.BUSINESS_CATEGORIES}`)
        dispatch( {
            type: GET_BUSINESS_CATEGORIES,
            payload: res.data
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: BUSINES_CATEGORIES_ERROR,
            payload: error,
        })
    }

}

