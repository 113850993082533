import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const ContactusAction = async (formData) => {

    try {

        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        const response = await axios.post(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.CONTACTUS}`, formData)
        
        

        return response;


    }
    catch (error) {
        console.log(error);
        return error;


    }

}


