// not using this code

import { GET_USER_PROFILE, GET_USER_PROFILE_ERROR } from '../types'

const initialState = {
    getUserProfileData: [],
    loading_getUserProfile: true
}

export default function (state = initialState, action) {

    switch (action.type) {

        case GET_USER_PROFILE:
            return {
                ...state,
                getUserProfileData: action.payload,
                loading_getUserProfile: false

            }
        case GET_USER_PROFILE_ERROR:
            return {
                loading_getUserProfile: false,
                error: action.payload
            }
        default: return state
    }

}