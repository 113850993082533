import {GET_FAVORITE, GET_FAVORITE_ERROR} from '../types'

const initialState = {
    favoriteVendors:[],
    loading_favoriteVendors:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_FAVORITE:
        return {
            ...state,
            favoriteVendors:action.payload,
            loading_favoriteVendors:false

        }
        case GET_FAVORITE_ERROR:
            return{
                loading_favoriteVendors: false, 
                error: action.payload 
            }
        default: return state
    }

}