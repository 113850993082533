import React, { useEffect, useState } from 'react'
import '../../assets/css/adobe-fine-living.css';
import { useDispatch, useSelector } from 'react-redux'
import GoogleMapReact from 'google-map-react';
import Map_pin from "../Map_pin";
import { getVendorDetailsAction } from '../../actions/getVendorDetailsAction';
import { MarkFavoriteVendorAction } from '../../actions/MarkFavoriteVendorAction';
import BannerWithSearch from '../BannerWithSearch';
import { useNavigate } from 'react-router-dom';

const VendorDetails = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(getVendorDetailsAction(JSON.parse(localStorage.getItem('vendorDetails'))))
    }, [dispatch])

    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])

    const getVendorDetails = useSelector(state => state.getVendorDetails)
    const { loading_getVendorDetails, getVendorDetailsData } = getVendorDetails

   
    const markFavorite = (vendor_id, favorite) => {
        const token = localStorage.getItem('token');
        if (token != null && token != '') {
            const formData = new FormData();
            formData.append('vendor_id', vendor_id);
            formData.append('favorite', favorite);
            MarkFavoriteVendorAction(token, formData)
                .then((result) => {
                    let vendor_detail = JSON.parse(localStorage.getItem('vendorDetails'))
                    let fav = vendor_detail.favorite
                    vendor_detail.favorite = fav == 1 ? 0 : 1;
                    dispatch(getVendorDetailsAction(vendor_detail))
                    localStorage.setItem('vendorDetails', JSON.stringify(vendor_detail))

                }).catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <div class="rightside ">
            <BannerWithSearch />

            {
                !loading_getVendorDetails ?
                    <section class="loaction_section mr-3">
                        <div class="row inner_row find_row  vendor-list-fav-icon">
                            <div class="col-xs-12 col-sm-12 col-md-12 mb-5">
                                <div class="Adobe_Fine_Living_heading">
                                    <div class="Adobe_Fine_Living_img">
                                        <img src={getVendorDetailsData.logo} alt="" />
                                    </div>
                                    <div class="Adobe_Fine_Living_text">
                                        <h3>{getVendorDetailsData.name}</h3>
                                    </div>
                                </div>
                                <div id="holder">
                                    <div class={`box ${getVendorDetailsData.favorite == 1 ? "entypo-thumbs-down" : "entypo-thumbs-up"}`}
                                        onClick={() => {

                                            markFavorite(getVendorDetailsData.id, getVendorDetailsData.favorite == 0 ? 1 : 0);
                                        }}
                                    > Like</div>
                                    <div class="tb"><a ><img src="/images/tb.svg" /></a></div>
                                </div>
                            </div>
                        </div>
                        <div class="row inner_row location_row">
                            <div class="col-xs-6 col-sm-6 col-md-6 mb-4 mr-4">
                                <div class="location_div">
                                    <div class="location_detail">
                                        <div class="location_icon">
                                            <img src="/images/Address.png" alt="Address icon" />
                                        </div>
                                        <div class="location_info">
                                            <h3>Address</h3>
                                            <address>
                                                {getVendorDetailsData.address_1}
                                            </address>
                                        </div>
                                    </div>
                                    <div class="location_detail">
                                        <div class="location_icon">
                                            <img src="/images/Phone-Number.png" alt="Address icon" />
                                        </div>
                                        <div class="location_info">
                                            <h3>Phone Number</h3>
                                            <a href={`tel:${getVendorDetailsData.phone}`}>{getVendorDetailsData.phone}</a>
                                        </div>
                                    </div>
                                    <div class="location_detail">
                                        <div class="location_icon">
                                            <img src="/images/Email-Address.png" alt="Address icon" />
                                        </div>
                                        <div class="location_info">
                                            <h3>Email Address</h3>
                                            <a href={`mailto:${getVendorDetailsData.email}`}>{getVendorDetailsData.email}</a>
                                        </div>
                                    </div>
                                    <div class="location_detail">
                                        <div class="location_icon">
                                            <img src="/images/Website.png" alt="Address icon" />
                                        </div>
                                        <div class="location_info">
                                            <h3>Website</h3>
                                            <a href={`http://${getVendorDetailsData.website}`} target="_blank">{getVendorDetailsData.website}</a>
                                        </div>
                                    </div>
                                    <div class="social_icon_div">
                                        {
                                            getVendorDetailsData.facebook != null ?
                                                <div class="social_icon">
                                                    <img src="/images/Facebook-icon.png" alt="Address icon" />
                                                    <a href={getVendorDetailsData.facebook}>Facebook</a>
                                                </div>
                                                : null
                                        }

                                        {
                                            getVendorDetailsData.instagram != null ?
                                                <div class="social_icon">
                                                    <img src="/images/Instagram-icon.png" alt="Address icon" />
                                                    <a href={getVendorDetailsData.instagram}>Instagram</a>
                                                </div>
                                                : null
                                        }


                                        {getVendorDetailsData.virtual_showroom1 != null ?
                                            <div class="social_icon">
                                                <img src="/images/Virtual-Showrooms-icon.png" alt="Address icon" />
                                                <a href={getVendorDetailsData.virtual_showroom1}>Virtual Showrooms</a>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    {
                                        getVendorDetailsData.note != null ?

                                            <div class="Additional_Vendor_Contact_div">
                                                <h2>Additional Vendor Contact:</h2>
                                                <div class="Additional_Vendor_Contact">
                                                    <div class="Vendor_Name">
                                                        <div class="location_icon">
                                                            <img src="/images/Additional-Vendor-Contact.png" alt="Address icon" />
                                                        </div>
                                                        <div class="location_info">
                                                            <h3>Vendor Rep</h3>
                                                            <p>
                                                                {getVendorDetailsData.note}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="Vendor_Number">
                                                        <div class="location_icon">
                                                            <img src="/images/Phone-Number.png" alt="Address icon" />
                                                        </div>
                                                        <div class="location_info">
                                                            <h3>Rep Direct Number</h3>
                                                            <a href={getVendorDetailsData.phone}>{getVendorDetailsData.phone}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                            </div>
                            <div class="col-xs-6 col-sm-6 col-md-6 mb-4">
                                <div class="map_div">
                                    <div style={{ height: '100vh', width: '100%' }}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: `${process.env.REACT_APP_MAP_KEY}` }}
                                            defaultCenter={{
                                                lat: getVendorDetailsData.location.latitude,
                                                lng: getVendorDetailsData.location.longitude
                                            }}
                                            defaultZoom={11}

                                        >
                                            <Map_pin
                                                details = {getVendorDetailsData}
                                                lat={getVendorDetailsData.location.latitude}
                                                lng={getVendorDetailsData.location.longitude}
                                            />


                                        </GoogleMapReact>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </section>
                    : null
            }
        </div>

    );
};

export default VendorDetails;