import {GET_ACTIVE_TAG, ACTIVE_TAG_ERROR} from '../types'

const initialState = {
    activeTag:"",
    loading_activeTag:true
}

export default function(state = initialState, action){
    switch(action.type){
        
        case GET_ACTIVE_TAG:
            return {
            ...state,
            activeTag:action.payload,
            loading_activeTag:false

        }
        case ACTIVE_TAG_ERROR:
            return{
                loading_activeTag: false, 
                error: action.payload 
            }
        default: return state
    }

}