import { combineReducers } from 'redux'
import userReducer from './usersReducer'
import categoriesReducer from './categoriesReducer'
import subMenu1Reducer from './subMenu1Reducer'
import subMenu2Reducer from './subMenu2Reducer'
import activeCategoryReducer from './activeCategoryReducer'
import activeSubCategoryReducer from './activeSubCategoryReducer'
import loginReducer from './loginReducer'
import isLoginReducer from './isLoginReducer'
import getPageReducer from './getPageReducer'
import designCenterReducer from './designCenterReducer'
import vendorsListByMapReducer from './vendorsListByMapReducer'
import getVendorDetailsReducer from './getVendorDetailsReducer'
import vendorsListByCategoryReducer from './vendorsListByCategoryReducer'
import getFavoriteVendorsReducer from './getFavoriteVendorsReducer'
import getSearchDataReducer from './getSearchDataReducer'
import getEventDetailsReducer from './getEventDetailsReducer'
import getBusinessCategoriesReducer from './getBusinessCategoriesReducer'
import getVirtualShowroomsReducer from './getVirtualShowroomsReducer'
import getProjectsReducer from './getProjectsReducer'
import getCurrentPageReducer from './getCurrentPageReducer'
import getProjectBoardIdReducer from './getProjectBoardIdReducer'
import getUseProfileReducer from "./getUseProfileReducer";
import getBreadcurmbReducer from "./getBreadcurmbReducer";
import activeTagReducer from "./activeTagReducer";




export default combineReducers({
  usersList:  userReducer,
  categoriesList: categoriesReducer,
  subMenu1List:subMenu1Reducer,
  subMenu2List:subMenu2Reducer,
  activeCategory:activeCategoryReducer,
  activeSubCategory:activeSubCategoryReducer,
  login:loginReducer,
  isLogin:isLoginReducer,
  getPage:getPageReducer,
  designCenter:designCenterReducer,
  vendorsListByMap:vendorsListByMapReducer,
  getVendorDetails:getVendorDetailsReducer,
  vendorsListByCategory:vendorsListByCategoryReducer,
  getFavoriteVendors:getFavoriteVendorsReducer,
  getSearchData:getSearchDataReducer,
  getEventDetails:getEventDetailsReducer,
  getBusinessCategories:getBusinessCategoriesReducer,
  getVirtualShowrooms:getVirtualShowroomsReducer,
  getProjects:getProjectsReducer,
  getCurrentPage:getCurrentPageReducer,
  getProjectBoardId:getProjectBoardIdReducer,
  getUseProfile:getUseProfileReducer,
  getBreadcurmb:getBreadcurmbReducer,
  activeTag:activeTagReducer,
})