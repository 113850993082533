import {GET_SEARCH_DATA, GET_SEARCH_DATA_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import { is_login } from './is_login';


export const getSearchDataAction = (token,search_txt) => async dispatch => {
    
    try{
        
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        
        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.SEARCH:ROUTE.SEARCH_BEFORE_LOGIN}/${search_txt}`;
        const res = await axios.get(API,config);
        dispatch( {
            type: GET_SEARCH_DATA,
            payload: res.data
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: GET_SEARCH_DATA_ERROR,
            payload: error,
        })
    }

}

export const getSearchData = async (token,search_txt) => {
    
    try{
        
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        
        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.SEARCH:ROUTE.SEARCH_BEFORE_LOGIN}/${search_txt}`;
        const res = await axios.get(API,config);
        return res;
    }
    catch(error){
        console.log(error);
       
    }

}

