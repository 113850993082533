import {GET_CURRENT_PAGE, GET_CURRENT_PAGE_ERROR} from '../types'
import axios from 'axios'

export const setCurrentPageAction = (currentPage) => async dispatch => {
    
    try{
        // const res = await axios.get('http://jsonplaceholder.typicode.com/users')
        dispatch( {
            type: GET_CURRENT_PAGE,
            payload: currentPage
        })
    }
    catch(error){
        dispatch( {
            type: GET_CURRENT_PAGE_ERROR,
            payload: error,
        })
    }

}