import React, { useEffect, useState } from 'react';
import { ShareProjectAction } from '../../actions/ShareProjectAction';
import $ from "jquery";

const ShareDedignBoard = ({ contacts }) => {

    const [newContacts, setNewContacts] = useState([]);
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        
        window.scrollTo(0, 0)

    }, [])
    useEffect(() => {

        setNewContacts(contacts)
    }, [contacts])

    const checkEmailExist = (email) => {
        return newContacts.filter((item) => {
            return item.email == email;
        })
    }

    const SendEmail = () => {
        setIsLoading(true)
        

        var emailList = newContacts.filter((item) => {
            return item.checked == true && item.email != '';
        }).map((item) => { return item.email });

        if (emailList.length > 0) {
            const formData = new FormData();
            formData.append('project_id', localStorage.getItem('selectedProject'));
            formData.append('message', message);
            formData.append('contacts', JSON.stringify(emailList));
            ShareProjectAction(localStorage.getItem('token'), formData)
                .then((res) => {
                    setIsLoading(false)

                    alert(res.data.response.message)
                    $(".fade").click();

                })
                .catch(error => { console.log(error); })
        } else {
            alert("please add email address");
            setIsLoading(false)
        }

    }



    return (
        <div class="share_form">
            {
                isLoading ?
                    <div class="loader-wrapper">
                        <div class="loader"></div>
                    </div>
                    : null
            }
            <div class="modal fade" id="shareDesignBoardModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container h-100">
                                <div class="d-flex justify-content-center h-100">
                                    <div class="user_card">
                                        <div class="d-flex justify-content-center">
                                            <div class="brand_logo_container">
                                                <img src="images/logo.jpg" class="brand_logo" alt="Logo" />
                                            </div>
                                            <h3 style={{ marginBottom: 0 }}>Share Board</h3>
                                        </div>
                                        <div class="d-flex justify-content-center form_container">
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                SendEmail();
                                            }}
                                            >
                                                <div class="input-group mb-3">
                                                    <label class="custom-control-label" for="Email">Email</label>
                                                    <input type="email" name="Email" class="form-control input_user" value={email}
                                                        onChange={(e) => { setEmail(e.target.value); setEmailError('') }}
                                                    />
                                                    <span class="plus"
                                                        onClick={() => {
                                                            
                                                            if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
                                                                if (checkEmailExist(email).length == 0) {
                                                                    setNewContacts([...newContacts, { "email": email, "checked": true }]);
                                                                    setEmail('');
                                                                }
                                                            } else {
                                                                setEmailError("Invalid email formate!")
                                                            }

                                                        }}
                                                    >+</span>
                                                </div>
                                                {
                                                    emailError != '' ?
                                                        <div style={{ color: "red" }}>{emailError}</div>
                                                        : null
                                                }

                                                <div class="form-group mb-2">
                                                    <label class="custom-control-label" for="Message">Message</label>
                                                    <textarea onChange={(e) => { setMessage(e.target.value) }}>{message}</textarea>
                                                </div>
                                                <div class="col-xs-12 col-sm-12 col-md-12 mb-4">
                                                    {
                                                        newContacts.map((contact, contact_index) => (

                                                            contact.email != "" && contact.email != null ?

                                                                <div class="form-group checkbox_style">
                                                                    <input
                                                                        type="checkbox"
                                                                        name={contact.email}
                                                                        id={contact.eamil}
                                                                        value={contact.email}
                                                                        checked={contact.checked}
                                                                        onChange={() => {
                                                                            var temp_contacs = newContacts;
                                                                            temp_contacs[contact_index] = { "email": contact.email, "checked": !contact.checked };
                                                                            setNewContacts([
                                                                                ...temp_contacs,
                                                                            ]);
                                                                        }}
                                                                    />
                                                                    <label for={contact.email}>{contact.email}</label>
                                                                    <span class="minus"
                                                                        onClick={() => {
                                                                            setNewContacts(newContacts.filter((item) => { return item.email != contact.email }))
                                                                        }}
                                                                    >-</span>
                                                                </div>
                                                                : null
                                                        ))
                                                    }
                                                    
                                                </div>
                                                <div class="d-flex justify-content-center mt-3 login_container">
                                                    <button type="submit" name="button" class="btn login_btn">Send Email</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
            </div> --> */}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ShareDedignBoard;