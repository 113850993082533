import {GET_DESIGN_CENTER, GET_DESIGN_CENTER_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import { is_login } from './is_login';

export const getDesignCentersAction = (token) => async dispatch => {
    
    try{
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.DESIGN_CENTERS:ROUTE.DESIGN_CENTERS_BEFORE_LOGIN}`;
        const res = await axios.get(API,config);
        dispatch( {
            type: GET_DESIGN_CENTER,
            payload: res.data
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: GET_DESIGN_CENTER_ERROR,
            payload: error,
        })
    }

}

