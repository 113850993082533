import React, { useEffect, useState } from 'react'
import '../../assets/css/news.css';
import { getPage as get_page } from '../../actions/getPageAction'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import { Parser } from 'html-to-react'
import BannerWithSearch from '../BannerWithSearch';
import MenuButtons from '../Dashboard/MenuButtons';
import "../../assets/css/brands.css"

const WhatsNew = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(get_page(localStorage.getItem('token'), localStorage.getItem('slug')))
    }, [dispatch])



    const get_page_data = useSelector(state => state.getPage)
    const { loading_getPage, getPageData } = get_page_data


    return (

        <div class="rightside">
            <BannerWithSearch />

            {
                !loading_getPage && getPageData.status == 1 ?
                    Parser().parse(getPageData.response.page.content)
                : null
            }
            <MenuButtons />

        </div>
    );
};

export default WhatsNew;