import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/event.css";
import "../../assets/js/eventCalendar";




import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

// import events from "./events";
import { getEventDetailsAction } from '../../actions/getEventDetailsAction';
import { useDispatch, useSelector } from 'react-redux';
import { getEventsAction } from '../../actions/getEventsAction';
import { getFormattedDateTime, getMarkedDates } from '../Functions';
import $ from "jquery";
import moment from 'moment';
import BannerWithSearch from '../BannerWithSearch';
import MenuButtons from '../Dashboard/MenuButtons';
import { setBreadcurmbAction } from '../../actions/setBreadcurmbAction';



const EventCalendar = () => {
    let navigate = useNavigate();

    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    const [newEvents, setNewEvents] = useState([]);
    useEffect(() => {

        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                console.log(isLogin);
                navigate('/dashboard');
            }
        }
        
        let prev_selevted_cal_date = localStorage.getItem('calSelectedData');
        if (prev_selevted_cal_date != null && prev_selevted_cal_date != '') {
            setNewDate(prev_selevted_cal_date)
            gotoNewDate(prev_selevted_cal_date)
        }
        else {
            setNewDate(moment(new Date()).format('YYYY-MM'))
        }

        getEventsAction(localStorage.getItem('token'))
            .then((result) => {
        
                setNewEvents(getMarkedDates(result.response.events))
            })
            .catch(error => {
                console.log(error);
            })
        window.scrollTo(0, 0)


        $(".fc-icon-chevron-left").click(() => {
            let cal_date = calendarRef.current._calendarApi.view.getCurrentData().viewTitle;
            let selectedDate = moment(new Date(cal_date)).subtract(1, 'M').format('YYYY-MM')
            setNewDate(selectedDate)
            localStorage.setItem('calSelectedData', selectedDate)

        })

        $(".fc-icon-chevron-right").click(() => {
            let cal_date = calendarRef.current._calendarApi.view.getCurrentData().viewTitle;
            let selectedDate = moment(new Date(cal_date)).add(1, 'M').format('YYYY-MM')
            setNewDate(selectedDate)
            localStorage.setItem('calSelectedData', selectedDate)

        })



    }, [])

    const [newDate, setNewDate] = useState();
    const calendarRef = useRef();
    const gotoNewDate = (newDate) => {
        let calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(newDate); // call a method on the Calendar object
    };

    const dispatch = useDispatch();



    return (
        <div class="rightside">
            <BannerWithSearch />
            
            <section class="main_event">
                <div class="row inner_row event_row_checker">
                    <div class="col-xs-12 col-sm-12 col-md-12 mb-5">
                        <div class="main_event_heading_text">
                            <h3>Design Events:</h3>
                        </div>
                        <div class="checkbox_style_div">
                            <form>
                                <div class="form-group">
                                    <label class="container-checkbox am">Day Event
                                        <input type="checkbox" checked={false} />
                                        <span class="checkmark" ></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label class="container-checkbox pm">Evening Event
                                        <input type="checkbox" checked={false} />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>

                                <div class="form-group">
                                    <label class="container-checkbox registered">Registered
                                        <input type="checkbox" checked="checked" />
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <div class="row inner_row calender_wapper calender">
                    <div className='datePiker'>
                        <h5 className='me-3'>Jump to</h5>
                        <input type="month"
                            onChange={(e) => {
                                setNewDate(e.target.value);
                                gotoNewDate(e.target.value);
                                localStorage.setItem('calSelectedData', e.target.value);
                                

                            }}
                            value={newDate}
                        />
                    </div>
                    <FullCalendar

                        defaultView="dayGridMonth"
                        header={{

                            left: "prev,next",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay"
                        }}
                        ref={calendarRef}
                        themeSystem="Simplex"
                        plugins={[dayGridPlugin, interactionPlugin]}
                        events={newEvents}
                        eventContent={renderEventContent}
                        
                        eventClick={

                            (e) => {
                                dispatch(setBreadcurmbAction(e.event.title, () => {
                                    // console.log(`it working ${e.event.title}`)
                                    // dispatch(getSubMenu2([]));
                                    // dispatch(removeBreadcurmbAction(5));
                                    // navigate('/login_dashboard');
                                }, 3));
                                localStorage.setItem('event', JSON.stringify({ "id": e.event.id }));
                                dispatch(getEventDetailsAction(localStorage.getItem('token'), { "id": e.event.id }))
                                navigate('/event_details');
                                
                            }
                        }

                    />

                </div>
            </section>
            <MenuButtons />

        </div>
    );
};

function renderEventContent(eventInfo) {
    return (
        <>
            <div class={`wapper_event ${eventInfo.event.backgroundColor}`}>
                <div class="div_heading_event_bg">

                    <span class="heading_event">{eventInfo.event.title}
                        {
                            eventInfo.event.extendedProps.rsvp > 0 ?
                                <div class="righ_event_img">
                                    <img src="/images/evnet_right.svg" />
                                </div>
                                : null
                        }
                    </span>
                    <br />

                    <span class="time_event">{`${getFormattedDateTime(eventInfo.event.extendedProps.date_time, 'h:mm a')} - ${getFormattedDateTime(eventInfo.event.extendedProps.end_date_time, 'h:mm a')}`}</span>
                </div>
            </div>

        </>
    )
}

export default EventCalendar;

