import {GET_ACTIVE_CATEGORIES, ACTIVE_CATEGORIES_ERROR} from '../types'

export const getActiveCategory = (category_id) => async dispatch => {
    
    try{
        dispatch( {
            type: GET_ACTIVE_CATEGORIES,
            payload: category_id
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: ACTIVE_CATEGORIES_ERROR,
            payload: error,
        })
    }

}

