import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getPage as get_page } from '../../actions/getPageAction'
import { getProjectsActions } from '../../actions/getProjectsActions'
import { useDispatch, useSelector } from 'react-redux'
import CreateProject from './CreateProject';
import { getFormattedDateTime } from '../Functions';
import { DeleteProjectAction } from '../../actions/DeleteProjectAction';
import { setCurrentPageAction } from '../../actions/setCurrentPageAction';
import ShareDesignBoard from '../Editor_Board/ShareDesignBoard';
import { setProjectBoardId } from '../../actions/setProjectBoardId';
import { Button, Modal } from 'react-bootstrap';
import Categories from '../Categories/Categories';
import BannerWithSearch from '../BannerWithSearch';
import MenuButtons from './MenuButtons';


const ProjectBoards = () => {

    const dispatch = useDispatch()
    let navigate = useNavigate();
    const isLoginData = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = isLoginData

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(getProjectsActions(localStorage.getItem('token')))
        dispatch(setCurrentPageAction("login_dashboard"))
    }, [dispatch])



    const getProjects = useSelector(state => state.getProjects)
    const { loading_getProjects, getProjectsData } = getProjects
    const isProjectsExists = !loading_getProjects && getProjectsData.status == 1;
    const projects = isProjectsExists ? getProjectsData.response.projects : [];

    const deleteProject = (project_id) => {

        const isDelete = window.confirm("Are you sure to delete project")
        if (isDelete) {

            DeleteProjectAction(localStorage.getItem('token'), project_id)
                .then((result) => {
                    alert(result.response.message)

                    dispatch(getProjectsActions(localStorage.getItem('token')))

                })
                .catch(error => {
                    console.log(error);
                })
        }

    }

    const [contactEmails, setContactEmails] = useState([]);
    const [fistProjectMessageModal, setfistProjectMessageModal] = useState(false);
    const [fistProjectMessage, setfistProjectMessage] = useState("false");

    const [activeTab, setActiveTab] = useState("");


    return (
        <>
            <ShareDesignBoard contacts={contactEmails} />
            <CreateProject />
            <Modal show={fistProjectMessageModal}
                onHide={() => {
                    // setfistProjectMessageModal(false);
                }}
                centered>
                <Modal.Body>
                    <span>{fistProjectMessage}</span>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn_note m-0 me-2" onClick={() => { setfistProjectMessageModal(false); }} >
                        Ok
                    </Button>

                </Modal.Footer>
            </Modal>
            <div class="rightside">
                <BannerWithSearch />

                <section class="create_project_board">
                    <div class="row inner_row create_project_post_div mb-5">
                        <h3>My Boards</h3>
                        <div class="project_board_row">
                            {
                                isProjectsExists ?
                                    projects.map((project, index) => (

                                        <div class="project_board_list" key={project.id} >
                                            <Link to="/editor_board" state={{ contacts: [{ "email": project.contact, "checked": true }, { "email": project.contact2, "checked": true }] }}
                                                onClick={() => {
                                                    localStorage.setItem('selectedProject', project.id);
                                                    localStorage.setItem('contacts', JSON.stringify([{ "email": project.contact, "checked": true }, { "email": project.contact2, "checked": true }]));
                                                    dispatch(setProjectBoardId(project.id))
                                                }}
                                            >
                                                <div class="image_project" key={project.shade_board_id}

                                                >
                                                    <img src={project.image} alt={project.name} />
                                                </div>
                                            </Link>
                                            <div class="text_project">
                                                <div class="edit_post">
                                                    <a class="dropdown-toggle">...</a>
                                                    <ul class="dropdown">
                                                        <li><Link to="/editor_board" state={{ contacts: [{ "email": project.contact, "checked": true }, { "email": project.contact2, "checked": true }] }}
                                                            onClick={() => {
                                                                localStorage.setItem('selectedProject', project.id);
                                                                localStorage.setItem('contacts', JSON.stringify([{ "email": project.contact, "checked": true }, { "email": project.contact2, "checked": true }]));
                                                                dispatch(setProjectBoardId(project.id))
                                                            }}
                                                        >Edit</Link></li>
                                                        <li><a href="#" onClick={() => { deleteProject(project.id) }}>Delete</a></li>
                                                        <li><a href="#" data-bs-toggle="modal" data-bs-target="#shareDesignBoardModal"
                                                            onClick={() => {
                                                                // localStorage.setItem('contacts', JSON.stringify([{ "email": project.contact, "checked": true },{ "email": project.contact2, "checked": true }]));
                                                                localStorage.setItem('selectedProject', project.id);
                                                                setContactEmails([{ "email": project.contact, "checked": true }, { "email": project.contact2, "checked": true }])
                                                            }}>Share</a></li>
                                                    </ul>
                                                </div>
                                                <span class="date">Created On: {project.created_on == "" ? "-" : getFormattedDateTime(project.created_on, 'MM DD, YYYY')} </span>
                                                <h4>{project.name}</h4>
                                                <p class="dec">{project.client_name == "" ? "-" : `${project.client_name} | ${project.additional_client_name}`}</p>

                                            </div>
                                        </div>

                                    ))


                                    : null
                            }
                            
                            <div class="project_board_list div_added_new_project">
                                <a
                                    href="#"
                                    class="added_new_project"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => {

                                        if ("popup_messge" in getProjectsData.response) {
                                            setfistProjectMessage(getProjectsData.response.popup_messge)
                                            setfistProjectMessageModal(true)
                                        }
                                    }}
                                >
                                    <div class="image_project">
                                        <img src="images/added_project.svg" alt="added_project " />
                                        <h4>Create New Project Board</h4>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <MenuButtons />

            </div>
        </>

    );
};

export default ProjectBoards;