import {GET_SUBMENU2, SUBMENU2_ERROR} from '../types'


export const getSubMenu2 = (singleMenu) => async dispatch => {
    
    try{
        dispatch( {
            type: GET_SUBMENU2,
            payload: singleMenu
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: SUBMENU2_ERROR,
            payload: error,
        })
    }

}


