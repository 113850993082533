import {GET_LOGIN, LOGIN_ERROR} from '../types'

const initialState = {
    userData:[],
    loading:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_LOGIN:
        return {
            ...state,
            categories:action.payload,
            loading:false

        }
        case LOGIN_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}