import {GET_EVENT_DETAILS, GET_EVENT_DETAILS_ERROR} from '../types'

const initialState = {
    getEventDetailsData:[],
    loading_getEventDetailsData:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_EVENT_DETAILS:
        return {
            ...state,
            getEventDetailsData:action.payload,  
            loading_getEventDetailsData:false

        }
        case GET_EVENT_DETAILS_ERROR:
            return{
                loading_getEventDetailsData: false, 
                error: action.payload 
            }
        default: return state
    }

}