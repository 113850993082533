import {GET_VENDORS_LIST_MAP, GET_VENDORS_LIST_MAP_ERROR} from '../types'

const initialState = {
    vendorsListByMapData:[],
    designCenter:[],
    loading_vendorsListByMapData:true
}

export default function(state = initialState, action){
    switch(action.type){
        
        case GET_VENDORS_LIST_MAP:
            return {
            ...state,
            vendorsListByMapData:action.payload.res,
            designCenter:action.payload.designCenter,
            loading_vendorsListByMapData:false

        }
        case GET_VENDORS_LIST_MAP_ERROR:
            return{
                loading_vendorsListByMapData: false, 
                error: action.payload 
            }
        default: return state
    }

}