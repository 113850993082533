import {GET_PAGE, GET_PAGE_ERROR} from '../types'

const initialState = {
    getPageData:[],
    loading_getPage:true
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_PAGE:
        return {
            ...state,
            getPageData:action.payload,  
            loading_getPage:false

        }
        case GET_PAGE_ERROR:
            return{
                loading_getPage: false, 
                error: action.payload 
            }
        default: return state
    }

}