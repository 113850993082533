import React, { useEffect, useState } from 'react'
import '../../assets/css/arizona.css';
import { isLogin as login } from '../../actions/isLoginAction'
import { getDesignCentersAction } from '../../actions/getDesignCentersAction'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import BannerWithSearch from '../BannerWithSearch';
import MenuButtons from '../Dashboard/MenuButtons';
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';


const DesignCentersFindByMap = () => {

    const dispatch = useDispatch();
    let navigate = useNavigate();
    
    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])
   

    useEffect(() => {
        dispatch(login(localStorage.getItem('token')))
        dispatch(getDesignCentersAction(localStorage.getItem('token')))
    }, [dispatch])


   

    const designCenter = useSelector(state => state.designCenter)
    const { loading_designCentersData, designCentersData } = designCenter

   

    const viewDesignCenters = (designCenter)=>{
        localStorage.setItem('designCenter',JSON.stringify(designCenter))
        navigate('/vendors_list');
    }


    return (

        <div class="rightside">
            <BannerWithSearch />
            
            {
                // !loading && isLogin.status == 1 ?
                    !loading_designCentersData && designCentersData.status == 1 ?
                        <section class="arizona_map">
                            <div class="row inner_row arizona_map_post mb-5">
                                <h3>Arizona</h3>
                                <div class="arizona_map_row" >
                                    {
                                        designCentersData.response.map((item, index) => (
                                            
                                            <div class="arizona_map_list" key={item.id} onClick={()=>{
                                                viewDesignCenters(item)
                                                dispatch(setBreadcurmbAction(item.name,()=>{
                                                    
                                                    dispatch(removeBreadcurmbAction(2));
                                                    navigate('/vendors_list');
                                                },2));
                                            }} 
                                            style={{cursor:"pointer"}}
                                            > 
                                                <div class="map_image">
                                                    <iframe
                                                        className='iframe'
                                                        src={item.image}
                                                        allowfullscreen="false" 
                                                        loading="lazy"
                                                        width="500"
                                                        height="500"
                                                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                                                </div>
                                                <div class="map_text">
                                                    <h4>{item.name}</h4>
                                                </div>
                                            </div>
                                        ))


                                    }
                                </div>

                            </div>
                        </section>
                        : null
                    // : null
            }
            <MenuButtons />
        </div>

    );
};

export default DesignCentersFindByMap;