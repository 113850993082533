import React, { useEffect, useState } from 'react'
import "../../assets/css/logindashboard.css"
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import BannerWithSearch from '../BannerWithSearch';
import MenuButtons from './MenuButtons';


const LoginDashboard = () => {
    let navigate = useNavigate();
    const isLoginData = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = isLoginData

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])


    return (
        <>
            <div class="rightside">
                <BannerWithSearch />
                <MenuButtons />
            </div>
        </>
    );
};



export default LoginDashboard;