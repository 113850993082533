import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getSearchDataAction, getSearchData as getSearchDataFromDB } from '../actions/getSearchDataAction';
import { getVendorDetailsAction } from '../actions/getVendorDetailsAction';
import { vendorsListByCategoryAction } from '../actions/vendorsListByCategoryAction';
import { getEventDetailsAction } from '../actions/getEventDetailsAction';
import { getFormattedDateTime } from './Functions';
import { setProjectBoardId } from '../actions/setProjectBoardId';
import { removeBreadcurmbAction, setBreadcurmbAction } from '../actions/setBreadcurmbAction';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
const SearchField = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();


    const [search, setSearch] = useState('');
    const getSearch = (search_txt) => {
        setSearch(search_txt)
        dispatch(getSearchDataAction(localStorage.getItem('token'), search_txt))
    }

    
    const vendorsListByCategory = (category) => {

        localStorage.setItem('category', JSON.stringify(category));
        dispatch(vendorsListByCategoryAction(localStorage.getItem('token'), category))

        navigate('/vendor_list_category');
    }

    const vendorsDetail = (vendor) => {
        localStorage.setItem('vendorDetails', JSON.stringify(vendor));
        dispatch(getVendorDetailsAction(vendor))

        navigate('/vendor_details');
    }

    const eventDetail = (event) => {

        localStorage.setItem('event', JSON.stringify(event));
        dispatch(getEventDetailsAction(localStorage.getItem('token'), event))
        navigate('/event_details');
    }

    const getBreadcurmb = useSelector(state => state.getBreadcurmb)
    const { loading_breadcrumbsList, breadcrumbsList } = getBreadcurmb
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]


    const _loadData = (searchValue, callback) => {
        const newSearch = [];
        
        getSearchDataFromDB(localStorage.getItem('token'), searchValue).then(res => {
            const searchData = res.data;

            if (searchData.response.categories.length > 0) {
                newSearch.push({ value: "Categories", label: `Categories`, key: '0' })
                searchData.response.categories.map((category, index) => {
                    newSearch.push({
                        value: `${category.name} - ${category.parent.name}`,
                        label: `${category.name} - ${category.parent.name}`,
                        key: '1',
                        action: () => {
                            vendorsListByCategory(category)
                            dispatch(removeBreadcurmbAction(1));
                            dispatch(setBreadcurmbAction(`${category.name} - ${category.parent.name}`, () => {
                                dispatch(removeBreadcurmbAction(1));
                                
                            }, 1));
                        }
                    })
                })
            }
            if (searchData.response.vendors.length > 0) {
                newSearch.push({ value: "Vendors", label: `Vendors`, key: '0' })
                searchData.response.vendors.map((vendor, index) => {
                    newSearch.push({
                        value: `${vendor.name}`,
                        label: `${vendor.name}`,
                        key: '1',
                        action: () => {
                            vendorsDetail(vendor);
                            dispatch(removeBreadcurmbAction(1));
                            dispatch(setBreadcurmbAction(`${vendor.name}`, () => {
                                dispatch(removeBreadcurmbAction(1));
                                
                            }, 1));
                        }
                    })
                })
            }
            if (searchData.response.events.length > 0) {
                newSearch.push({ value: "Events", label: `Events`, key: '0' })
                searchData.response.events.map((event, index) => {
                    newSearch.push({
                        value: `${event.name} | ${getFormattedDateTime(event.created_on, 'MM-DD-YYYY')}`,
                        label: `${event.name} | ${getFormattedDateTime(event.created_on, 'MM-DD-YYYY')}`,
                        key: '1',
                        action: () => {
                            eventDetail(event);
                            dispatch(removeBreadcurmbAction(1));
                            dispatch(setBreadcurmbAction(`${event.name}`, () => {
                                dispatch(removeBreadcurmbAction(1));
                                
                            }, 1));
                        }
                    })
                })
            }
            if (searchData.response.projects.length > 0) {
                newSearch.push({ value: "Project", label: `Project`, key: '0', })
                searchData.response.projects.map((project, index) => {
                    newSearch.push({
                        value: `${project.name}`,
                        label: `${project.name}`,
                        key: '1',
                        action: () => {
                            localStorage.setItem('selectedProject', project.id);
                            localStorage.setItem('contacts', JSON.stringify([{ "email": project.contact, "checked": true },{ "email": project.contact2, "checked": true }]));
                            
                            dispatch(setProjectBoardId(project.id));
                            dispatch(removeBreadcurmbAction(1));
                            dispatch(setBreadcurmbAction(`${project.name}`,()=>{
                                dispatch(removeBreadcurmbAction(1));
                                
                            },1));
                            navigate('/editor_board');

                        }
                    })
                })
            }
            callback(newSearch);
        })



    }



    const loadData = debounce(_loadData, 500);


    const handleChange = (selectedOptoin) => {
        selectedOptoin.action();
    }

    const colorStyle = {
        option:(styles,option)=>{
            return option.data.key == 0?
            {...styles,color:"#444", fontSize: 18, fontWeight:700, textAlign:"left"}
            :
            {...styles,color:"#444", fontSize:14,fontWeight:500, textAlign:"left", marginLeft:15}
        },
        singleValue: styles => ({ ...styles,color:"#8e8e8e", textAlign:"left"}),
        control: styles => ({ ...styles,color:"#8e8e8e", textAlign:"left"}),
        

    }
    return (
        <>
            <AsyncSelect loadOptions={loadData} onChange={handleChange} styles={colorStyle} placeholder="Quick Find" />
        </>

    )

};

export default SearchField;