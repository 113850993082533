import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { isLogin as login } from '../../actions/isLoginAction'
import DynamicMenu from './DynamicMenu';
import StaticMenu from "./StaticMenu";
import SearchField from '../SearchField';
import { is_login as isLogin_function } from '../../actions/is_login';
import SidebarBeforeLogin from './SidebarBeforeLogin';
import SidebarAfterLogin from './SidebarAfterLogin';

const Sidebar = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(login(localStorage.getItem('token')))
    }, [dispatch])

    const is_login = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = is_login



    return (
        
        <>
            {!loading && isLogin.status == 1 ? <SidebarAfterLogin /> : <SidebarBeforeLogin/>}
        </>
    );
};

export default Sidebar;