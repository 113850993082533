import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const DeleteProjectAction = async (token,project_id) => {

    try {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.DELETE_PROJECT}/${project_id}`, config)
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }

}


