import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const ForgotPassword = async (email) => {

    try {
        
        const body = {
            email,
        }

        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.RECOVER_PWD}`,
            data: body,
        });

        const res = response.data;
        
        return response;

        
    }
    catch (error) {
        console.log(error);
        return error;

        
    }

}


