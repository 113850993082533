import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveTagActions } from '../../actions/activeTagActions';
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { getSubMenu1 } from '../../actions/subMenu1Actions';
import { getSubMenu2 } from '../../actions/subMenu2Actions';
import MenuItems from './MenuItems';
import { getPage as get_page } from '../../actions/getPageAction'
import { closeSidebar } from '../Functions';


const SidebarAfterLogin = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();

    const is_login = useSelector(state => state.isLogin)
    const { loading, error, isLogin } = is_login;

    const menuItems1 = [
        {
            img: "/images/bycategories.svg",
            name: "By Categories",
            slug: null,
            to: "/categories",
            loginRequired: false,
            breadcrumbTitle: "Find By Categories",
            tag: "categories",
        },
        {
            img: "/images/bymap.svg",
            name: "By Map",
            slug: null,
            to: "/find_by_map",
            loginRequired: false,
            breadcrumbTitle: "Find By Map",
            tag: "findByMap",

        },
        {
            img: "/images/events.svg",
            name: "Events",
            slug: null,
            to: "/eventscalendar",
            loginRequired: false,
            breadcrumbTitle: "Events",
            tag: "events",
        },
        {
            img: "/images/what’s_new.svg",
            name: "What’s New",
            slug: "whats-new_desktop",
            to: "/whats_new",
            loginRequired: false,
            breadcrumbTitle: "What’s New",
            tag: "WhatsNew",
        },

    ];

    const menuItems2 = [
        {
            img: "/images/Favorites.svg",
            name: "Favorites",
            slug: null,
            to: "/vendor_list_favorite",
            loginRequired: false,
            breadcrumbTitle: "Favorites",
            tag: "favorites",

        },
        {
            img: "/images/VirtualShowrooms.svg",
            name: "Virtual Showrooms",
            slug: null,
            to: "/virtual_showrooms",
            loginRequired: false,
            breadcrumbTitle: "Virtual Showrooms",
            tag: "virtualShowrooms",

        },
        {
            img: "/images/Associations.svg",
            name: "Associations",
            slug: "professional-associations_web",
            to: "/associations",
            loginRequired: false,
            breadcrumbTitle: "Associations",
            tag: "associations",

        },
        {
            img: "/images/MarketsExpos.svg",
            name: "Markets/Expos",
            slug: "markets-expos_web",
            to: "/martek_expos",
            loginRequired: false,
            breadcrumbTitle: "Markets/Expos",
            tag: "marketsExpos",
        },
    ]


    return (
        <div class="sidebar"  id="sidebar">
            <nav>
                <div class="mobile_narbar">
                    <a href="#">|||</a>
                </div>
            </nav>
            <div class="mobile_menu show">
                <div class="logo-details">
                    {/* <span class="logo_name">TRADE FINDRY</span> */}
                    <span class="logo_name"><Link to="/login_dashboard" onClick={() => {
                        dispatch(getActiveTagActions(""));
                        dispatch(getSubMenu1([]));
                        dispatch(getSubMenu2([]));
                        dispatch(removeBreadcurmbAction(0));
                        // navigate('/login_dashboard');
                        closeSidebar();

                    }}>TRADE FINDRY</Link></span>

                </div>
                <div class="menu_srcoll">
                    <div class="profile_text">
                        <a href="#" class="profile_word">{isLogin.response.user.first_name[0].toUpperCase()}</a>
                        <h3>Welcome, {isLogin.response.user.first_name}!</h3>
                        <p><a href="#" data-bs-toggle="modal" data-bs-target="#userProfileModal">Edit Profile</a></p>
                    </div>
                    <div class="cat_seach">
                        <h4>Find</h4>
                    </div>
                    <MenuItems itemList={menuItems1} />
                    <div class="cat_seach">
                        <h4>Quick Links</h4>
                    </div>
                    <MenuItems itemList={menuItems2} />
                    <div class="withoutlogin_btn_div">
                        <div class="contact_us mt-2 mb-4">
                            <Link to="/brand" class="same_btn brand"
                                onClick={() => {

                                    dispatch(get_page(localStorage.getItem('token'), 'brands_web'))
                                    localStorage.setItem('slug', "brands_web");


                                    dispatch(setBreadcurmbAction('Brand Spotlight', () => {
                                        navigate("/brand");
                                    }, 1));
                                    closeSidebar();

                                }}
                            >Brand Spotlight</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidebarAfterLogin;