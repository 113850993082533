import {GET_PAGE, GET_PAGE_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";


export const getPage = (token,slug) => async dispatch => {
    
    try{
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.GET_PAGE}/${slug}`;
        const res = await axios.get(API,config);
        dispatch( {
            type: GET_PAGE,
            payload: res.data
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: GET_PAGE_ERROR,
            payload: error,
        })
    }

}

