import React from "react";
import { Navigate, Redirect, Route } from "react-router-dom";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = false
  console.log("this", isAuthenticated);

  return (
    isAuthenticated ? <Component /> : <Navigate to="/dashboard" replace />
  );
}

export default ProtectedRoute;