import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const getShadeBoardsAction = async (token) => {

    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.SHADE_BOARDS}`;
        const res = await axios.get(API,config);

        if(res.status == 1 ){
            localStorage.setItem('token', res.response.token);
        }



        return res.data;

        
    }
    catch (error) {
        console.log(error);
        return error;

        
    }

}


