
export const PATH = "/api/v1/";

export const ROUTE = {

    LOGIN: 'login',
    LOGOUT: 'logout',
    IS_LOGGEDIN: 'is_logged_in',
    SIGNUP: 'register',
    RECOVER_PWD: 'forgot_password',
    CATEGORIES: 'categories',
    VIRTUAL_SHOWROOMS: 'showrooms',
    CATEGORIES_BEFORE_LOGIN: 'categories_before_login',
    BUSINESS_CATEGORIES: 'business_categories',
    DESIGN_CENTERS: 'design_centers',
    DESIGN_CENTERS_BEFORE_LOGIN: 'design_centers_before_login',
    VENDORS_BY_DESIGN_CENTER: 'vendors_by_design_area',
    VENDORS_BY_DESIGN_CENTER_BEFORE_LOGIN: 'vendors_by_design_area_before_login',
    VENDORS_BY_CATEGORY: 'vendors_by_category',
    VENDORS_BY_CATEGORY_BEFORE_LOGIN: 'vendors_by_category_before_login',
    EVENTS_BEFORE_LOGIN: 'events_before_login',
    EVENTS: 'events',
    EVENT_BY_ID_BEFORE_LOGIN: 'event_by_id_before_login',
    EVENT_BY_ID: 'event_by_id',
    SUBMIT_RSVP_FOR_EVENT: 'submit_rsvp_for_event',
    REGISTER_FOR_FCM: 'register_fcm',
    PROJECTS: 'projects',
    PROJECTS_BEFORE_LOGIN: 'projects_before_login',
    SHADE_BOARDS: 'shade_boards',
    ADD_PROJECT: 'add_project',
    UPDATE_PROJECT: 'update_project',
    PROJECT_BY_ID: 'project_by_id',
    UPDATE_PROJECT_BOARD: 'update_project_board',
    GET_PROJECT_BOARD: 'get_project_board',
    DELETE_PROJECT: 'delete_project',
    GET_PAGE: 'get_page',
    TF_GALLERY: 'gallery',
    SEARCH: 'search',
    SEARCH_BEFORE_LOGIN: 'search_before_login',
    GET_CLIENT_LIST: 'get_client_list',
    SHARE_PROJ_BOARD: 'share_project_board',
    MARK_FAVORIT: 'mark_favorite',
    GET_FAV: 'get_favorites',
    GET_PROFILE: 'get_profile',
    UPDATE_PROFILE: 'update_profile',
    CONTACTUS:'contactus'
}


export const professionalAffiliationOpt = [
    {
        id: 0,
        name: 'ASID'
    },
    {
        id: 1,
        name: 'IIDA'
    },
    {
        id: 2,
        name: 'IFDA'
    },
    {
        id: 3,
        name: 'NKBA'
    },
    {
        id: 4,
        name: 'AIA'
    },
    {
        id: 5,
        name: 'NAR'
    },
    {
        id: 6,
        name: 'Other'
    }

];

export const recaptcha_site_key = "6Lego7kkAAAAAJC-KdXdXhMoayz1qGD1KCFyZOpK";
export const recaptcha_secrete_key = "6Lego7kkAAAAAPQn8rg_vqGH4Cy_kXDuU-VOHh9W";

export const firebaseConfig = {
    apiKey: "AIzaSyAm2XxlpsfA54ksROD0_HHtS8U4Sqe8kto",
    authDomain: "tradefindry.firebaseapp.com",
    projectId: "tradefindry",
    storageBucket: "tradefindry.appspot.com",
    messagingSenderId: "41787475162",
    appId: "1:41787475162:web:95e5b5df44023f050ad1e3",
    measurementId: "G-PNH75TEXXY"
  };
  

