import {GET_VENDORS_LIST_CATEGORY, GET_VENDORS_LIST_CATEGORY_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import {is_login} from './is_login';

export const vendorsListByCategoryAction = (token,category) => async dispatch => {
    try{

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        

        const API = `${process.env.REACT_APP_SERVER_HOST}${PATH}${is_login()?ROUTE.VENDORS_BY_CATEGORY:ROUTE.VENDORS_BY_CATEGORY_BEFORE_LOGIN}/${category.id}`;
        const res = await axios.get(API,config);

        dispatch( {
            type: GET_VENDORS_LIST_CATEGORY,
            payload: {"res":res.data,"category":JSON.stringify(category)}
        })
    }
    catch(error){
        console.log(error);
        dispatch( {
            type: GET_VENDORS_LIST_CATEGORY_ERROR,
            payload: error,
        })
    }

}

