import {PROJECT_BOARD_ID, PROJECT_BOARD_ID_ERROR} from '../types'
import axios from 'axios'

export const setProjectBoardId = (board_id) => async dispatch => {
    
    try{
        dispatch( {
            type: PROJECT_BOARD_ID,
            payload: board_id
        })
    }
    catch(error){
        dispatch( {
            type: PROJECT_BOARD_ID_ERROR,
            payload: error,
        })
    }

}