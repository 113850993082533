import React from 'react';
import $ from "jquery";
import "../../assets/css/userprofile.css";
import UserProfile from './UserProfile';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const Header2 = () => {

  const is_login = useSelector(state => state.isLogin)
  const { loading, error, isLogin } = is_login

  return (
    <>
      <header class="header">
        <div class="myrow loggend_header">
          <div class="left_side left_side_afterLogin">
            <div class="mobile_narbar" onClick={() => {
              $(".sidebar").toggleClass("active-s");
            }}>
              <span class="dropdown-toggle mobile_narbar">|||</span>
            </div>
          </div>
          <div class="right_btn">
            <OverlayTrigger
              placement="bottom"
              
              overlay={<Tooltip >User Profile</Tooltip>}
            >
              <a href="#" class="yi_n mr-1" data-bs-toggle="modal" data-bs-target="#userProfileModal">{isLogin.response.user.first_name[0].toUpperCase()}</a>
            </OverlayTrigger>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Logout</Tooltip>}
            >
              <a href="#" class="yi_backpage" onClick={() => {
                localStorage.setItem('token', "");
                localStorage.clear();
                window.location.replace("/");
              }}>Backpage</a>

            </OverlayTrigger>


          </div>
        </div>
      </header>

      <UserProfile />

    </>

  );
};

export default Header2;