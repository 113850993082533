import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { SendRSVPForEventAction } from "../../actions/SendRSVPForEventAction";
const EventRSVPForm = ({ closeRSVPModal, event_id }) => {
    useEffect(() => {
        // console.log("in RSVP ", event_id);
        window.scrollTo(0, 0)

    }, [])

    const [email, setEmail] = useState("");
    const [noOfAttendees, setNoOfAttendees] = useState("");


    const submitRSVPForm = () => {
        if (noOfAttendees != "" && email != "") {
            const formData = new FormData();
            formData.append('event_id', event_id);
            formData.append('no_of_participants', noOfAttendees);
            formData.append('email', email);

            SendRSVPForEventAction(localStorage.getItem('token'), formData).then((result) => {
                const res = result.data;
                alert(res.response.message);
                if (res.status == 1) {
                    closeRSVPModal();
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }
    return (
        <>
            <Modal show={true}
                onHide={() => { closeRSVPModal() }}
                centered>
                <Modal.Body>
                    <div class="container h-100">
                        <div class="user_card">
                            <div class="d-flex justify-content-center form_container show_bottom">
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    submitRSVPForm();
                                }}>
                                    <div class="input-group mb-3">
                                        <label class="custom-control-label" for="Email">Email</label>
                                        <input type="email" name="Email" class="form-control input_user" value={email}
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                    <div class="input-group mb-2">
                                        <label class="custom-control-label" for="Number of Attendees">Number of Attendees</label>
                                        <input type="number" min={1} name="Password" class="form-control input_pass" value={noOfAttendees}
                                            onChange={(e) => { setNoOfAttendees(e.target.value) }}
                                        />
                                    </div>

                                    <div class="d-flex justify-content-center mt-3 login_container atten_btn">
                                        <button type="button" name="button" class="btn login_btn close m-1"
                                            onClick={() => { closeRSVPModal() }}
                                        >Cancel</button>
                                        <button type="submit" name="button" class="btn login_btn m-1">OK</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

export default EventRSVPForm;