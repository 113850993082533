import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const CreateProjectAction = async (token,formData) => {

    try {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await axios.post(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.ADD_PROJECT}`, formData, config)
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }

}


