import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getActiveCategory } from '../../actions/activeCategoryActions';
import { getCategories } from '../../actions/categoriesActions';
import { getSubMenu1 } from '../../actions/subMenu1Actions';
import { getSubMenu2 } from '../../actions/subMenu2Actions';
import { vendorsListByCategoryAction } from '../../actions/vendorsListByCategoryAction';
import SubCategoryList from './SubCategoryList';
import "../../assets/css/find.css"
import { getPage as get_page } from '../../actions/getPageAction'
import { removeBreadcurmbAction, setBreadcurmbAction } from '../../actions/setBreadcurmbAction';
import { getActiveTagActions } from '../../actions/activeTagActions';
import BannerWithSearch from '../BannerWithSearch';
import MenuButtons from '../Dashboard/MenuButtons';


const Categories = () => {

    let navigate = useNavigate();

    const dispatch = useDispatch()
    const categoriesList = useSelector(state => state.categoriesList)
    const { loading, error, categories } = categoriesList

    const activeCategory = useSelector(state => state.activeCategory)
    const active_category = activeCategory.activeCategory

    const subMenu1List = useSelector(state => state.subMenu1List)
    const { subMenu1 } = subMenu1List;
 

    const subMenu2List = useSelector(state => state.subMenu2List);
    const { subMenu2 } = subMenu2List;
   
    const isLoginData = useSelector(state => state.isLogin);
    const {isLogin } = isLoginData;

    useEffect(() => {
        if (localStorage.getItem('token') == null || localStorage.getItem('token') == "") {
            if (isLogin.status != 1) {
                navigate('/dashboard');
            }
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        dispatch(getCategories())
    }, [dispatch])

    const setSubMenu = (singleMenu) => {
        if (singleMenu.children) {
            dispatch(getActiveCategory(singleMenu.id))
            if (singleMenu.parent_id == 0) {
                dispatch(getSubMenu1(singleMenu))
            } else {

                dispatch(getSubMenu2(singleMenu))
            }
        }
        else {

            dispatch(getActiveCategory(0))
            localStorage.setItem('category', JSON.stringify(singleMenu));
            dispatch(vendorsListByCategoryAction(localStorage.getItem('token'), singleMenu))
            navigate('/vendor_list_category');
        }
    }
    return (
        // <div >
        <div class="rightside">
            <BannerWithSearch />
            {
                !subMenu2.id && !subMenu1.id && loading == false ?
                    <>
                        <section class="find_category_section mt-3">
                            <div class="myrow inner_container">
                                <div class="col_12">
                                    <h3>Find By Category:</h3>
                                </div>
                                <div class="col_12 category_div">
                                    <ul class="category_list">
                                        {categories.response.map((item, index) => (
                                            
                                            <li class={`category_single cat_residential ${active_category == item.id ? 'active' : ''}`} key={item.id}
                                                onClick={() => {
                                                    setSubMenu(item);
                                                    dispatch(setBreadcurmbAction(item.name,
                                                        () => {
                                                            dispatch(getSubMenu2([]));
                                                            dispatch(removeBreadcurmbAction(2));
                                                            navigate(item.children ? '/categories' : '/vendor_list_category');
                                                        }, 2));

                                                }}
                                            >
                                                <a>
                                                    <img src={item.image} alt={item.name} />
                                                    <h4>{item.name.replace("/", " / ")}</h4>
                                                </a>
                                            </li>

                                        ))
                                        }

                                        

                                    </ul>
                                </div>
                            </div>
                        </section>
                        
                    </>
                    :
                    !subMenu2.id && subMenu1.id && !subMenu1List.loading ?
                        <SubCategoryList categoryList={subMenu1} setSubMenu={setSubMenu} tagIndex={3} />
                        :
                        subMenu2.id && !subMenu2List.loading ?
                            <SubCategoryList categoryList={subMenu2} setSubMenu={setSubMenu} tagIndex={4} />
                            : null
            }
            <MenuButtons />

        </div>
    );
};

export default Categories;