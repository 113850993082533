import { GET_LOGIN, LOGIN_ERROR } from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";

export const SendRSVPForEventAction = async (token,formData) => {
    try {

        const config = {     
            headers: { 'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
         }
        }

        const response = await axios.post(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.SUBMIT_RSVP_FOR_EVENT}`, formData, config)
        return response;

        
    }
    catch (error) {
        console.log(error);
        return error;
    }

}


