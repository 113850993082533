import {GET_PROJECTS, GET_PROJECTS_ERROR} from '../types'
import axios from 'axios'
import { ROUTE, PATH } from "../constants";
import { is_login } from './is_login';

export const getProjectBoardActions = async (token,projectId) => {
    
    try {

        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        const response = await axios.get(`${process.env.REACT_APP_SERVER_HOST}${PATH}${ROUTE.PROJECT_BY_ID}/${projectId}`, config)
        return response.data;
    }
    catch (error) {
        console.log(error);
        return error;
    }

}

